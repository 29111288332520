const parameterisedRoute = (pattern = '', params = {}) => {
  const keys = Object.keys(params);

  if (!keys.length) return pattern;

  // Replace parameters with real values.
  return keys.reduce((acc, key) => {
    return acc.replace(new RegExp(`:${key}(\\([^\\)]*\\))?`), params[key]);
  }, pattern);
};

/* Routes */
export const Routes = {
  root: () => '/',
  photos: () => '/photos',
  girls: () => '/girls',
  login: () => '/login',
  signup: () => '/signup',
  signupPayment: () => '/signup/payment',
  forgotPassword: () => '/forgot-password',
  forgotPasswordWithToken: ({ id = ':id', token = ':token' } = {}) =>
    `/forgot-password/${id}/${token}`,
  featuredCategory: ({ slug = ':slug' } = {}) => `/category/${slug}`,
  video: (params = {}) => parameterisedRoute('/video/:slug([0-9a-z-_]+)-:id(\\d+)', params),
  girl: (params = {}) => parameterisedRoute('/:slug([0-9a-z-_]+)-nude-g:id(\\d+)', params),
  accountSettings: () => '/settings/account',
  accountBilling: () => '/settings/billing',
  deviceAuth: () => '/device/auth',
  copyrightAgent: () => '/copyrightagent',
  terms: () => '/terms',
  cams: () => '/cams',
  camsIframe: () => '/cams/iframe',
  camsPixel: () => '/cams/pixel',
  privacy: () => '/privacy',
  compliance: () => '/2257',
  refunds: () => '/refunds',
  styleguide: () => '/styleguide',
  tour: () => '/tour',
  tourLogin: () => '/tour/login',
  tourSignup: () => '/tour/signup',
  tourSignupPayment: () => '/tour/signup/payment',
  tourForgotPassword: () => '/tour/forgot-password',
  tourForgotPasswordWithToken: ({ id = ':id', token = ':token' } = {}) =>
    `/tour/forgot-password/${id}/${token}`,
  girlTour: (params = {}) => parameterisedRoute('/tour/:slug([0-9a-z-_]+)-nude-g:id(\\d+)', params),
  billingSupportUrl: () => 'https://www.vusprt.com/',
  affliateUrl: () => 'https://www.mrskincash.com/',
};

/* Web App */
export const DASHBOARD_ROUTES = [Routes.root(), Routes.featuredCategory()];
export const VIDEO_ROUTES = [Routes.video()];
export const PHOTO_ROUTES = [Routes.photos()];
export const GIRLS_ROUTES = [Routes.girls(), Routes.girl()];
export const SETTINGS_ROUTES = [Routes.accountSettings(), Routes.accountBilling()];
export const DEVICE_AUTH_ROUTES = [Routes.deviceAuth()];

/**
  List all dynamic website pages
* */
export const MAIN_ROUTES = [
  ...DASHBOARD_ROUTES,
  ...VIDEO_ROUTES,
  ...PHOTO_ROUTES,
  ...GIRLS_ROUTES,
  ...SETTINGS_ROUTES,
  ...DEVICE_AUTH_ROUTES,
];

/**
  List all basic pages here (like terms & conditions)
* */
export const EXTRA_ROUTES = [
  Routes.terms(),
  Routes.privacy(),
  Routes.compliance(),
  Routes.refunds(),
  Routes.copyrightAgent(),
];

/**
  List all join-page routes here
* */
export const AUTH_ROUTES = [
  Routes.login(),
  Routes.signup(),
  Routes.signupPayment(),
  Routes.forgotPassword(),
  Routes.forgotPasswordWithToken(),
];

/**
  List all tours here
* */
export const VIDEO_TOUR_ROUTES = [
  Routes.tour(),
  Routes.tourLogin(),
  Routes.tourSignup(),
  Routes.tourSignupPayment(),
  Routes.tourForgotPassword(),
  Routes.tourForgotPasswordWithToken(),
];

/**
  List all tours here
* */
export const GIRL_TOUR_ROUTES = [Routes.girlTour()];
