import cardValidator from 'card-validator';
import {
  createValidator,
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthLessThan,
  hasLengthGreaterThan,
} from 'revalidate';

const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
const USERNAME_REGEX = /^(\d|\w)+$/i;
const NAME_REGEX = /^[a-z ,.'-]+$/i;
const CVV_REGEX = /^[0-9]{3,4}$/;
const EXP_DATE_REGEX = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;

/**
 * Custom validators.
 */

const isValidEmail = createValidator(
  message => value => (value && !EMAIL_REGEX.test(value) ? message : undefined),
  'Invalid email address'
);

const isUsernameValid = createValidator(
  message => value => (value && !USERNAME_REGEX.test(value) ? message : undefined),
  'Username must not have spaces or special characters'
);

const isValidName = createValidator(
  message => value => (value && !NAME_REGEX.test(value) ? message : undefined),
  'Please enter your full name'
);

const isValidCardNumber = createValidator(
  message => value => (!cardValidator.number(value).isValid ? message : undefined),
  'Invalid credit card number'
);

const isValidExpDate = createValidator(
  message => value => (value && !EXP_DATE_REGEX.test(value) ? message : undefined),
  'Invalid expiry date'
);

const isValidCvv = createValidator(
  message => value => (value && !CVV_REGEX.test(value) ? message : undefined),
  'Invalid CVV'
);

/**
 * Field specific validators.
 */

const usernameValidator = composeValidators(
  isRequired('Username'),

  hasLengthGreaterThan(2)({
    message: 'Must be at least 3 characters',
  }),

  hasLengthLessThan(15)({
    message: 'Must be less than 15 characters',
  }),

  isUsernameValid
)();

const passwordValidator = composeValidators(
  isRequired('Password'),

  hasLengthGreaterThan(5)({
    message: 'Must be at least 6 characters',
  })
)();

const emailValidator = composeValidators(isRequired('Email'), isValidEmail)();
const nameValidator = composeValidators(isRequired('Full Name'), isValidName)();
const cardNumberValidator = composeValidators(isRequired('Card Number'), isValidCardNumber)();
const expDateValidator = composeValidators(isRequired('Card Expiry'), isValidExpDate)();
const cvvValidator = composeValidators(isRequired('CVV'), isValidCvv)();

/**
 * Form specific validators.
 */

export const validateSignupForm = combineValidators({
  username: usernameValidator,
  password: passwordValidator,
  email: emailValidator,
});

export const validateLoginForm = combineValidators({
  login: emailValidator,
  password: isRequired('Password'),
});

export const validateDeviceAuthForm = combineValidators({
  password: isRequired('Password'),
});

export const validateForgotPasswordForm = combineValidators({
  email: emailValidator,
});

export const validatePaymentForm = combineValidators({
  name: nameValidator,
  cardNumber: cardNumberValidator,
  expDate: expDateValidator,
  cvv: cvvValidator,
});

export const validatePasswordResetForm = combineValidators({
  password: isRequired('Password'),
  password_confirmation: isRequired('Password'),
});
