import { useAppState } from 'Members/hooks';
import { Routes } from 'Members/const';

/* eslint-disable func-names */

/*
Parse the URL and figure out what category we're using
*/
export const getCategoryFromPathname = function(pathname) {
  const [{ featuredCategories = [] }] = useAppState();
  const category = featuredCategories.find(c => pathname === Routes.featuredCategory(c));

  return category ? category.name : undefined;
};

/*
We display the featured video, but we don't want it to be included in the
main thumbnail list. This checks if we have a category -- if set, only display
videos that have the same category. Then makes sure it is not the featured
video.
*/
export const getVideoList = function(videos, category, featured) {
  return videos.filter(video => {
    return (
      (category === undefined || video.category === category) &&
      (!featured || video.id !== featured.id)
    );
  });
};

export const displayCategoryFilter = function(unfilteredVideos, categoryPathname) {
  const category = getCategoryFromPathname(categoryPathname);
  const count = getVideoList(unfilteredVideos, category).length;

  return count > 0;
};
