import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TagLink } from 'Members/components/shared';
import { Routes } from 'Members/const';

const GirlTagLinks = ({ girls, className }) => {
  if (!girls || girls.length <= 0) return null;

  const containerCss = classNames('girl-tag-links-container', className);

  return (
    <div className={containerCss}>
      <span className="mr-2 text-uppercase">girls:</span>
      {girls.map(girl => (
        <TagLink key={girl.id} to={Routes.girl(girl)} className="mx-1">
          {girl.name}
        </TagLink>
      ))}
    </div>
  );
};

GirlTagLinks.propTypes = {
  girls: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

GirlTagLinks.defaultProps = {
  girls: [],
};

export default GirlTagLinks;
