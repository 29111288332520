/**
 * This allows to use FontAwesomeIcon component with text values for icons.
 *
 * E.g.:
 * <FontAwesomeIcon icon="coffee" />
 * <FontAwesomeIcon icon={['fab', 'instagram']} />
 *
 * More info on how it works here:
 * https://github.com/FortAwesome/react-fontawesome
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faSpinnerThird,
  faPlayCircle,
  faTimes,
  faUser,
  faLockAlt,
  faEnvelope,
  faCreditCard,
  faBars,
  faPaperPlane,
  faCheckCircle,
  faExclamationTriangle,
  faCheckSquare,
  faSquare,
  faInfoCircle,
  faCheck,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';

import {
  faPlay,
  faClone,
  faExternalLinkSquareAlt,
  faBroom,
} from '@fortawesome/pro-solid-svg-icons';

import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/pro-duotone-svg-icons';

library.add(
  fab,
  faSpinnerThird,
  faPlayCircle,
  faTimes,
  faUser,
  faLockAlt,
  faEnvelope,
  faCreditCard,
  faBars,
  faPaperPlane,
  faCheckCircle,
  faExclamationTriangle,
  faCheckSquare,
  faSquare,
  faInfoCircle,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faArrowCircleLeft,
  faArrowCircleRight,
  faPlay,
  faClone,
  faExternalLinkSquareAlt,
  faBroom
);
