import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubmitButton = ({ text, disabled, isSubmitting, loadingInstruction }) => {
  const css = classNames({
    'auth-form-submit': true,
    btn: true,
    'btn-accent': true,
    'btn-lg': true,
    disabled: disabled(),
  });

  return (
    <button type="submit" className={css} disabled={disabled()}>
      {isSubmitting && <FontAwesomeIcon icon={['fal', 'spinner-third']} spin />}
      {!isSubmitting && text}
      {isSubmitting && loadingInstruction}
    </button>
  );
};

SubmitButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  loadingInstruction: PropTypes.string,
};

SubmitButton.defaultPropTypes = {
  isSubmitting: false,
  loadingInstruction: null,
};

export default SubmitButton;
