import React from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'Members/hooks';
import { isPremium, RequireUserType } from 'Members/utils';
import Comment from './Comment';
import ReplyForm from './ReplyForm';

const CommentsList = ({ id, replyId, comments, onCommentReply }) => {
  // Hide the comments section for non premiums users if there are no comments.
  const [{ user }] = useAppState();
  const isVisible = isPremium(user) || (comments && comments.length > 0);

  return isVisible ? (
    <div className="comments-list-section bg-section text-section-contrast">
      <div className="comments-list-container">
        <div className="text-center mb-5">
          <h3 className="section-title">Comments</h3>
        </div>

        <div className="comments-list">
          {comments.map((comment, index) => (
            <Comment
              {...comment}
              key={comment.id}
              videoId={id}
              replyId={replyId}
              rootIndex={index}
              onReply={onCommentReply}
              root
            />
          ))}
        </div>

        <RequireUserType premium>
          <ReplyForm key={comments.length} videoId={id} />
        </RequireUserType>
      </div>
    </div>
  ) : null;
};

CommentsList.propTypes = {
  id: PropTypes.number.isRequired,
  replyId: PropTypes.number,
  comments: PropTypes.arrayOf(PropTypes.object),
  onCommentReply: PropTypes.func.isRequired,
};

CommentsList.defaultProps = {
  replyId: null,
  comments: [],
};

export default CommentsList;
