import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { AppLink } from 'Members/utils';
import { Logo } from 'Members/components/shared';
import { Routes, ANIMATION_DURATION } from 'Members/const';
import BackgroundVideo from 'Members/components/shared/background_video';
import SiteConfig from 'site_config';

const FullPageVideo = ({ location, children, videoUrl, logoRoute, ...rest }) => {
  return (
    <main {...rest}>
      <BackgroundVideo src={videoUrl}>
        <section className="auth-content container">
          <nav className="auth-navbar">
            <AppLink to={logoRoute} noscroll>
              <Logo className="img-fluid" />
            </AppLink>
          </nav>

          <TransitionGroup className="w-100">
            <CSSTransition
              key={location.pathname}
              classNames="auth-content"
              timeout={ANIMATION_DURATION}
            >
              {children}
            </CSSTransition>
          </TransitionGroup>
        </section>
      </BackgroundVideo>
    </main>
  );
};

FullPageVideo.defaultProps = {
  videoUrl: SiteConfig.videoTour.videoUrl,
  logoRoute: Routes.root(),
};

FullPageVideo.propTypes = {
  videoUrl: PropTypes.string,
  logoRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  location: PropTypes.object,
  children: PropTypes.object,
};

export default FullPageVideo;
