import React from 'react';
import { useAppState } from 'Members/hooks';
import { Messages } from 'Members/const';
import Payment from './Payment';

const GirlPayment = props => {
  const [{ girl }] = useAppState();
  const freeProductId = girl ? girl.productId : null;

  return (
    <Payment
      subTitle={Messages.girlPurchaseCta}
      freeProductId={freeProductId}
      trackViewAction={null}
      {...props}
    />
  );
};

export default GirlPayment;
