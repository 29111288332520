import { api, tracker, recaptcha } from 'Members/utils';
import SiteConfig from 'site_config';
import { AUTH_PURCHASE, FORM_ERRORS } from './types';
import { alertRequestSuccess } from './common';

export const getJoinFormData = async (params, dispatch) => {
  try {
    const { data } = await api.getPackage(params);
    dispatch(data);
  } catch ({ message }) {
    dispatch({ error: message });
  }
};

export const trackPurchaseView = async ({ id, purchaseFormName }) => {
  try {
    await api.trackPurchaseView({ id, purchaseFormName });
  } catch (e) {
    /* ignore any errors */
  }
};

export const fetchProductBillingGroup = async (dispatch, params) => {
  try {
    const { data } = await api.getProductBillingGroup(params);
    dispatch({ data });
  } catch ({ message }) {
    dispatch({ error: message });
  }
};

export const submitPaymentForm = async (
  dispatch,
  {
    crossSaleName,
    purchaseFormName,
    crossSaleId,
    packageId,
    billingPackages,
    billingGroupId,
    freeProductId,
    ...creditCard
  }
) => {
  try {
    dispatch({ type: FORM_ERRORS, message: null });
    tracker.purchaseSubmitAttempt({ crossSaleName, crossSaleId });

    const purchase = { crossSaleId, packageId, purchaseFormName, freeProductId };
    const recaptchaToken = await recaptcha.execute('purchase');
    const { data, meta } = await api.purchase({ creditCard, purchase, recaptchaToken });

    dispatch({ type: AUTH_PURCHASE, data });
    alertRequestSuccess(dispatch, `Thank you for joining ${SiteConfig.displayName}.`);

    tracker.purchase({
      transactionId: meta.transactionId,
      packageName: meta.packageName,
      packageId: meta.packageId,
      packageAmount: meta.packageAmount,
      crossSaleId,
      crossSaleName,
    });
  } catch ({ message }) {
    dispatch({ type: FORM_ERRORS, message });
  }
};

export const submitProductOneClickForm = async (dispatch, params) => {
  try {
    dispatch({ type: FORM_ERRORS, message: null });

    const { data, meta } = await api.purchaseProduct(params);

    dispatch({ type: AUTH_PURCHASE, data });
    alertRequestSuccess(dispatch, 'Thank you for subscribing.');

    tracker.purchase(meta);
  } catch ({ message }) {
    dispatch({ type: FORM_ERRORS, message });
  }
};
