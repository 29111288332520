/**
 * This utility component attaches analytics tracking to routing mechanism.
 * Whenever a route is changed, it's tracking a page view.
 * It also sets user ID when it's changed.
 */

import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { tracker } from 'Members/utils';
import { useAppState } from 'Members/hooks';

const Analytics = ({ children, location: { pathname } }) => {
  const [{ user, splitTest = {} }] = useAppState();
  const { experimentId, variationId = '0' } = splitTest;

  const userId = user ? user.id : null;
  const isPremium = !!(user && user.subscriptionStatus === 'active');

  // Update iZooto user profile on user state change.
  // We need it to target web push notifications.
  useEffect(() => {
    // eslint-disable-next-line
    if (user && window._izq) window._izq.push(['userProfile', { add: { isPremium } }]);
  }, [isPremium]);

  // IMPORTANT
  // The order of tracker settings is crucial.
  // First we set a user ID, then experiment ID and only then we track a page view.
  useEffect(() => {
    tracker.setUserId(userId);
  }, [userId]);

  useEffect(() => {
    if (experimentId && +variationId >= 0) {
      tracker.setUserExperiment({ experimentId, variationId: +variationId });
    }
  }, [experimentId]);

  useEffect(() => {
    tracker.pageview(pathname);
  }, [pathname]);

  return children;
};

export default withRouter(Analytics);
