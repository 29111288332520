import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

const LazyLoop = ({ src, className, ...rest }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const videoRef = useRef(null);
  const videoClassNames = classNames(className, 'lazy');

  useEffect(() => {
    setIsLoaded(false);

    const video = videoRef.current;
    const onVideoLoad = () => setIsLoaded(true);

    video.addEventListener('canplay', onVideoLoad);
    video.src = src;
    video.load();

    return () => {
      video.removeAttribute('src');
      video.removeEventListener('canplay', onVideoLoad);
    };
  }, [src]);

  return (
    <CSSTransition in={isLoaded} classNames="lazy" timeout={300}>
      <video
        ref={videoRef}
        className={videoClassNames}
        src={src}
        controls={false}
        autoPlay
        loop
        muted
        playsInline
        {...rest}
      />
    </CSSTransition>
  );
};

LazyLoop.propTypes = {
  src: PropTypes.string.isRequired,
};

export default LazyLoop;
