import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingProgress = () => {
  return (
    <div className="loading-progress">
      <FontAwesomeIcon icon={['fal', 'spinner-third']} size="3x" spin />
    </div>
  );
};

export default LoadingProgress;
