class Recaptcha {
  constructor({ api, key }) {
    this.api = api;
    this.key = key;
  }

  get isEnabled() {
    return this.api && this.key;
  }

  get isDisabled() {
    return !this.isEnabled;
  }

  async execute(action) {
    if (this.isDisabled) return Promise.resolve();

    return new Promise((resolve, reject) => {
      this.api.ready(() => {
        try {
          this.api.execute(this.key, { action }).then(token => resolve(token));
        } catch (error) {
          reject(error);
        }
      });
    });
  }
}

const recaptcha = new Recaptcha({
  api: window.grecaptcha,
  key: window.recaptchaSiteKey,
});

export default recaptcha;
