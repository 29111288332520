import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSlider } from 'Members/hooks';

const ThumbnailsCarousel = ({ small, medium, large, className, itemClassName, children }) => {
  const carouselRef = useRef(null);
  const carouselCss = classNames('thumbnails-carousel', className);
  const itemCss = classNames('thumbnails-carousel-item', itemClassName);

  // Hide arrows if there are not enough content in the carousel.
  const onResize = slider => {
    if (!carouselRef.current || !slider) return;

    if (slider.perPage >= children.length) {
      carouselRef.current.classList.add('--arrows-hidden');
    } else {
      carouselRef.current.classList.remove('--arrows-hidden');
    }

    slider.resizeHandler();
  };

  const { onSliderNext, onSliderPrevious, containerRef } = useSlider({
    total: children.length,
    perPage: { 0: small, 768: medium, 992: large },
    draggable: false,
    onResize,
  });

  return (
    <div className={carouselCss} ref={carouselRef}>
      <FontAwesomeIcon
        className="thumbnails-carousel-arrow m-2 m-sm-3"
        icon={['fal', 'chevron-left']}
        size="3x"
        onClick={onSliderPrevious}
      />

      <div className="thumbnails-carousel-content" ref={containerRef}>
        {children.map(child => (
          <div key={child.key} className={itemCss}>
            {child}
          </div>
        ))}
      </div>

      <FontAwesomeIcon
        className="thumbnails-carousel-arrow m-2 m-sm-3"
        icon={['fal', 'chevron-right']}
        size="3x"
        onClick={onSliderNext}
      />
    </div>
  );
};

ThumbnailsCarousel.propTypes = {
  small: PropTypes.number.isRequired,
  medium: PropTypes.number.isRequired,
  large: PropTypes.number.isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
};

export default ThumbnailsCarousel;
