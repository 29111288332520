/**
 * Common actions.
 */
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const HIDE_ALERT = 'HIDE_ALERT';
export const CONFIRM_SHOW = 'CONFIRM_SHOW';
export const CONFIRM_HIDE = 'CONFIRM_HIDE';

/**
 * Modal actions.
 */
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';

/**
 * Gallery viewer actions.
 */
export const GALLERY_VIEWER_SHOW = 'GALLERY_VIEWER_SHOW';
export const GALLERY_VIEWER_HIDE = 'GALLERY_VIEWER_HIDE';
export const GALLERY_VIEWER_NEXT = 'GALLERY_VIEWER_NEXT';
export const GALLERY_VIEWER_PREV = 'GALLERY_VIEWER_PREV';

/**
 * Auth specific actions.
 */
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_PURCHASE = 'AUTH_PURCHASE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

/**
 * Videos specific actions.
 */
export const VIDEOS_FETCHED = 'VIDEOS_FETCHED';
export const VIDEO_FETCHED = 'VIDEO_FETCHED';

/**
 * Girls specific actions.
 */
export const GIRL_FETCHED = 'GIRL_FETCHED';

/**
 * Single video specific actions.
 */
export const VIDEO_COMMENT_SUBMIT = 'VIDEO_COMMENT_SUBMIT';
export const VIDEO_COMMENT_REPLY = 'VIDEO_COMMENT_REPLY';

/**
 * Account settings specific actions.
 */
export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';

/**
 * Errors arr (showing inline)
 */
export const FORM_ERRORS = 'FORM_ERRORS';

/**
 * Device authorization actions.
 */
export const DEVICE_AUTH_STATUS = 'DEVICE_AUTH_STATUS';

/**
 * Split test specific actions.
 */
export const UPDATE_SPLIT_TEST = 'UPDATE_SPLIT_TEST';
