/*

*/
import PropTypes from 'prop-types';
import { useAppState } from 'Members/hooks';

const ExperimentVariant = ({ id, children }) => {
  const [{ splitTest = {} }] = useAppState();
  const { experimentId, variationId = '0' } = splitTest;

  return +id === +variationId || (experimentId === null && +id === 0) ? children : null;
};

ExperimentVariant.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ExperimentVariant;
