import React from 'react';
import { useAppState } from 'Members/hooks';
import { isGuest, isFreemium, isPremium } from 'Members/utils';
import { ModalName } from 'Members/const';
import { hideModal, showSignupModal, showPaymentModal } from 'Members/actions';

import {
  AccessModal,
  LoginModal,
  SignupModal,
  PaymentModal,
  ForgotPasswordModal,
  ResetPasswordModal,
  OneClickModal,
} from './auth_modal_types';

const AuthModal = () => {
  const [{ user, modal = null }, dispatch] = useAppState();
  const isPaymentModal = modal === ModalName.payment;
  const isOneClickModal = modal === ModalName.oneClick;
  const isAccessModal = modal === ModalName.access;

  document.body.classList.remove('modal-open');
  if (modal) document.body.classList.add('modal-open');

  // Allow any modal for guests except the payment modal and the one-click modal.
  if (isGuest(user) && modal && (isPaymentModal || isOneClickModal) && !isAccessModal) {
    showSignupModal(dispatch);
    return null;
  }

  // Redirect any modal for freemiums to the payment modal.
  if (isFreemium(user) && modal && !isPaymentModal && !isAccessModal) {
    showPaymentModal(dispatch);
    return null;
  }

  // Hide any modal for premiums except the one-click modal.
  if (isPremium(user) && modal && !isOneClickModal && !isAccessModal) {
    hideModal(dispatch);
    return null;
  }

  switch (modal) {
    case ModalName.access:
      return <AccessModal />;
    case ModalName.login:
      return <LoginModal />;
    case ModalName.signup:
      return <SignupModal />;
    case ModalName.payment:
      return <PaymentModal />;
    case ModalName.forgotPassword:
      return <ForgotPasswordModal />;
    case ModalName.resetPassword:
      return <ResetPasswordModal />;
    case ModalName.oneClick:
      return <OneClickModal />;
    default:
      return null;
  }
};

export default AuthModal;
