import React from 'react';

const RecaptchaNotice = () => {
  return (
    <div className="recaptcha-notice">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
        &nbsp;Privacy Policy&nbsp;
      </a>
      and
      <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
        &nbsp;Terms of Service&nbsp;
      </a>
      apply.
    </div>
  );
};

export default RecaptchaNotice;
