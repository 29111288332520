import React from 'react';
import { useAppState, useForm } from 'Members/hooks';
import { submitForgotPasswordForm } from 'Members/actions';
import { RecaptchaNotice } from 'Members/components/shared';
import InlineAlert from 'Members/components/InlineAlert';
import { SubmitButton, Input, validateForgotPasswordForm } from './Form';

const ForgotPassword = () => {
  const [, dispatch] = useAppState();

  const { values, edits, errors, formAlert, isSubmitting, isValid, onChange, onSubmit } = useForm(
    submitForgotPasswordForm.bind(this, dispatch),
    validateForgotPasswordForm
  );

  return (
    <form className="auth-forgot-password-form auth-form" onSubmit={onSubmit} noValidate>
      <h1>
        Forgot
        <br />
        Password
      </h1>

      {formAlert && <InlineAlert icon={{ icon: ['fal', 'exclamation-triangle'] }} {...formAlert} />}

      {!formAlert && (
        <fieldset>
          <Input
            type="email"
            name="email"
            icon={['fal', 'envelope']}
            placeholder="Email"
            autocomplete="email"
            value={values.email || ''}
            error={edits.email && errors.email}
            onChange={onChange}
          />
        </fieldset>
      )}

      <span className="auth-form-notice">
        {`Don't panic - we've got you covered. Give us the email you use to log in and we'll send you
        instructions for resetting your password.`}
      </span>

      {!formAlert && (
        <SubmitButton
          text="Help Me"
          disabled={() => isSubmitting || !isValid}
          isSubmitting={isSubmitting}
        />
      )}

      <RecaptchaNotice />
    </form>
  );
};

export default ForgotPassword;
