import React from 'react';
import PropTypes from 'prop-types';
import { LazyImage, VitalyPlayer, NestedGallerySlider } from 'Members/components/shared';

const isImage = src => /\.(png|jpg|jpeg|gif)/gi.test(src);
const isVideo = src => /\.(mp4|m3u8)|vimeo/gi.test(src);
const isNested = src => Array.isArray(src);

const VideoOrImageAsset = ({ src }) => {
  if (isVideo(src)) {
    return <VitalyPlayer src={src} />;
  }

  if (isImage(src)) {
    return <LazyImage src={src} spinner />;
  }

  return null;
};

const GalleryViewerAsset = ({ id, assetUrl }) => {
  return isNested(assetUrl) ? (
    <NestedGallerySlider key={id}>
      {assetUrl.map(url => (
        <VideoOrImageAsset key={url} src={url} />
      ))}
    </NestedGallerySlider>
  ) : (
    <VideoOrImageAsset key={id} src={assetUrl} />
  );
};

VideoOrImageAsset.propTypes = {
  src: PropTypes.string,
};

GalleryViewerAsset.propTypes = {
  id: PropTypes.number.isRequired,
  assetUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default GalleryViewerAsset;
