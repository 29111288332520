/**
 * We mainly need it for backward compatibility.
 * We want old auth routes to be properly redirected
 * to the corresponding modals on the home page.
 */

import { useRouteMatch, useParams } from 'react-router';
import { Routes, ModalName } from 'Members/const';

const ModalRedirects = Object.freeze({
  [Routes.login()]: ModalName.login,
  [Routes.signup()]: ModalName.signup,
  [Routes.signupPayment()]: ModalName.payment,
  [Routes.forgotPassword()]: ModalName.forgotPassword,
  [Routes.forgotPasswordWithToken()]: ModalName.resetPassword,
});

const handleAuthRouteModal = ({ route } = {}) => {
  const params = useParams() || {};
  const search = new URLSearchParams(params).toString();
  const { path } = useRouteMatch();
  const modal = ModalRedirects[route || path] || null;

  return { modal, search };
};

export default handleAuthRouteModal;
