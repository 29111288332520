import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSlider } from 'Members/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SLIDE_ANIMATION_DURATION = 200;

const SliderDots = ({ length, active, onClick }) => {
  return (
    <div className="nested-gallery-slider-dots">
      {Array.from({ length }, (item, index) => (
        <span
          key={index}
          className={classNames({ active: index === active })}
          onClick={onClick(index)}
        />
      ))}
    </div>
  );
};

const SliderCount = ({ length, active, onClick }) => {
  return (
    <div className="nested-gallery-slider-count" onClick={onClick}>
      {`${active + 1}/${length}`}
    </div>
  );
};

const NestedGallerySlider = ({ id, arrows, progress, overlap, children }) => {
  const onChange = (slider, container) => {
    if (!container) return;

    // Reset videos on slide change.
    window.setTimeout(() => {
      [...container.querySelectorAll('video')].forEach(video => {
        video.pause();
        video.currentTime = 0; // eslint-disable-line
      });
    }, SLIDE_ANIMATION_DURATION);
  };

  const {
    goToSlide,
    onSliderNext,
    onSliderPrevious,
    currentSlideIndex,
    hasNext,
    hasPrevious,
    containerRef,
  } = useSlider({
    total: children.length,
    perPage: 1,
    duration: SLIDE_ANIMATION_DURATION,
    onChange,
  });

  const sliderCss = classNames('nested-gallery-slider', { '--overlapping': overlap });
  const arrowCss = 'nested-gallery-slider-arrow d-none';
  const nextArrowCss = classNames(arrowCss, { 'd-sm-block': hasNext });
  const previousArrowCss = classNames(arrowCss, { 'd-sm-block': hasPrevious });
  const SliderProgress = progress === 'count' ? SliderCount : SliderDots;
  const onCountClick = progress === 'count' ? onSliderNext : index => () => goToSlide(index);

  return (
    <div className={sliderCss}>
      <div key={id} ref={containerRef} className="nested-gallery-slider-content">
        {children.map(child => (
          <div key={child.key} className="nested-gallery-slider-item">
            {child}
          </div>
        ))}
      </div>

      {arrows && (
        <Fragment>
          <FontAwesomeIcon
            className={previousArrowCss}
            icon={['fad', 'arrow-circle-left']}
            size="2x"
            onClick={onSliderPrevious}
          />
          <FontAwesomeIcon
            className={nextArrowCss}
            icon={['fad', 'arrow-circle-right']}
            size="2x"
            onClick={onSliderNext}
          />
        </Fragment>
      )}

      <SliderProgress length={children.length} active={currentSlideIndex} onClick={onCountClick} />
    </div>
  );
};

SliderDots.propTypes = {
  length: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

SliderCount.propTypes = {
  length: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

NestedGallerySlider.propTypes = {
  id: PropTypes.number.isRequired,
  arrows: PropTypes.bool,
  progress: PropTypes.oneOf(['count', 'dots']),
  overlap: PropTypes.bool,
};

NestedGallerySlider.defaultProps = {
  arrows: true,
  progress: 'count',
  overlap: false,
};

export default NestedGallerySlider;
