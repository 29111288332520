import { FORM_ERRORS } from 'Members/actions';

export default (errors, action) => {
  switch (action.type) {
    case FORM_ERRORS:
      return action.message;
    default:
      return errors;
  }
};
