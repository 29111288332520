import React from 'react';
import SiteConfig from 'site_config';

const Privacy = () => {
  return (
    <div className="extra-privacy container mb-5 mt-4">
      <h2>
        <b>PRIVACY POLICY</b>
      </h2>
      <p>
        {`Thank you for choosing to be part of our community at ${SiteConfig.displayName}. The
        ${SiteConfig.displayName} website is owned by ${SiteConfig.company.name}. We are committed to
        protecting your personal information and your right to privacy. If you have any questions
        or concerns about our policy, or our practices with regards to your personal information,
        please contact us at `}
        <a href={`mailto:${SiteConfig.company.supportEmail}`} target="_top">
          {SiteConfig.company.supportEmail}
        </a>
        .
      </p>
      <p>
        When you visit our website, and use our services, you trust us with your personal
        information. We take your privacy very seriously. In this privacy notice, we describe our
        privacy policy. We seek to explain to you in the clearest way possible what information we
        collect, how we use it and what rights you have in relation to it. We hope you take some
        time to read through it carefully, as it is important. If there are any terms in this
        privacy policy that you do not agree with, please discontinue use of our Apps and our
        services.
      </p>
      <p>
        This privacy policy applies to all information collected through our website, (“
        <b>Apps</b>
        “), and/or any related services, sales, marketing or events (we refer to them collectively
        in this privacy policy as the “<b>Sites</b>
        “).
      </p>
      <p>
        <b>
          Please read this privacy policy carefully as it will help you make informed decisions
          about sharing your personal information with us.
        </b>
      </p>
      <ol>
        <li>
          <b> WHAT INFORMATION DO WE COLLECT? &nbsp;</b>
        </li>
      </ol>
      <p>
        <b>Personal Information you disclose to us</b>
      </p>
      <p>
        <i>
          In Short: We collect personal information that you provide to us such as name, address,
          contact information, passwords and security data, and payment information. &nbsp;
        </i>
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when registering at the
        Apps, expressing an interest in obtaining information about us or our products and services,
        when participating in activities on the Apps or otherwise contacting us.
      </p>
      <p>
        The personal information that we collect depends on the context of your interactions with us
        and the Apps, the choices you make and the products and features you use. The personal
        information we collect can include the following:
      </p>
      <p>
        <b>Name and Contact Data.</b>
        We collect your first and last name, email address, postal address, phone number, and other
        similar contact data.
      </p>
      <p>
        <b>Credentials</b>. We collect passwords, password hints, and similar security information
        used for authentication and account access.
      </p>
      <p>
        <b>Payment Data</b>. We collect data necessary to process your payment if you make
        purchases, such as your payment instrument number (such as a credit card number), and the
        security code associated with your payment instrument. All payment data is stored by our
        payment processor and you should review its privacy policies and contact the payment
        processor directly to respond to your questions. &nbsp;
      </p>
      <p>
        All personal information that you provide to us must be true, complete and accurate, and you
        must notify us of any changes to such personal information. &nbsp;&nbsp;
      </p>
      <p>
        <b>Information collected through our Apps</b>
      </p>
      <p>
        <i>
          In Short: We may collect information regarding your push notifications, when you use our
          apps.
        </i>
      </p>
      <p>
        If you use our Apps, we may also collect the following information:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <ul>
        <li>
          <b>
            <i>Push Notifications</i>
          </b>
          . We may request to send you push notifications regarding your account or the website. If
          you wish to opt-out from receiving these types of communications, you may turn them off in
          your device’s settings. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </li>
      </ul>
      <p>
        <b>Information collected from other sources</b>
      </p>
      <p>
        <i>
          In Short: We may collect limited data from public databases, marketing partners, and other
          outside sources.
        </i>
      </p>
      <p>
        We may obtain information about you from other sources, such as public databases, joint
        marketing partners, as well as from other third parties. Examples of the information we
        receive from other sources include: social media profile information; marketing leads and
        search results and links, including paid listings (such as sponsored links). &nbsp;
      </p>
      <ol start="2">
        <li>
          <b> HOW DO WE USE YOUR INFORMATION? &nbsp;</b>
        </li>
      </ol>
      <p>
        <i>
          In Short: We process your information for purposes based on legitimate business interests,
          the fulfillment of our contract with you, compliance with our legal obligations, and/or
          your consent.
        </i>
      </p>
      <p>
        We use personal information collected via our Apps for a variety of business purposes
        described below. We process your personal information for these purposes in reliance on our
        legitimate business interests (“Business Purposes”), in order to enter into or perform a
        contract with you (“Contractual”), with your consent (“Consent”), and/or for compliance with
        our legal obligations (“Legal Reasons”). We indicate the specific processing grounds we rely
        on next to each purpose listed below. &nbsp;
      </p>
      <p>We use the information we collect or receive: &nbsp;</p>
      <ul>
        <li>
          <b>To facilitate account creation and logon process</b> with your Consent. If you choose
          to link your account with us to a third party account *(such as your Google or Facebook
          account), we use the information you allowed us to collect from those third parties to
          facilitate account creation and logon process.
        </li>
      </ul>
      <ul>
        <li>
          <b>To send you marketing and promotional communications</b> for Business Purposes. We
          and/or our third party marketing partners may use the personal information you send to us
          for our marketing purposes, if this is in accordance with your marketing preferences. You
          can opt-out of our marketing emails at any time (see the “WHAT ARE YOUR PRIVACY RIGHTS”
          below).
        </li>
      </ul>
      <ul>
        <li>
          <b>Fulfill and manage your orders</b> for Contractual reasons. We may use your information
          to fulfill and manage your orders, payments, returns, and exchanges made through the Apps.
        </li>
      </ul>
      <ul>
        <li>
          <b>Delivering targeted advertising to you</b> for our Business Purposes. We may use your
          information to develop and display content and advertising (and work with third parties
          who do so) tailored to your interests and/or location and to measure its effectiveness.
        </li>
      </ul>
      <ul>
        <li>
          <b>Request Feedback</b> for our Business Purposes. We may use your information to request
          feedback and to contact you about your use of our Apps.
        </li>
      </ul>
      <ul>
        <li>
          <b>To enforce our terms, conditions and policies</b> for Business Purposes, Legal Reasons
          and/or possibly Contractual.
        </li>
      </ul>
      <ul>
        <li>
          <b>To respond to legal requests and prevent harm</b> for Legal Reasons. If we receive a
          subpoena or other legal request, we may need to inspect the data we hold to determine how
          to respond.
        </li>
      </ul>
      <ul>
        <li>
          <b>For other business purposes</b>. We may use your information for other Business
          Purposes, such as data analysis, identifying usage trends, determining the effectiveness
          of our promotional campaigns and to evaluate and improve our Apps, products, services,
          marketing and your experience.
        </li>
      </ul>
      <ol start="3">
        <li>
          <b> WILL YOUR INFORMATION BE SHARED WITH ANYONE? &nbsp;</b>
        </li>
      </ol>
      <p>
        <i>
          In Short: We only share information with your consent, to comply with laws, to protect
          your rights, or to fulfill business obligations.
        </i>
      </p>
      <p>We only share and disclose your information in the following situations:</p>
      <ul>
        <li>
          <b>Compliance with Laws</b>. We may disclose your information where we are legally
          required to do so in order to comply with applicable law, governmental requests, a
          judicial proceeding, court order, or legal process, such as in response to a court order
          or a subpoena (including in response to public authorities to meet national security or
          law enforcement requirements).
        </li>
      </ul>
      <ul>
        <li>
          <b>Vital Interests and Legal Rights.</b>
          We may disclose your information where we believe it is necessary to investigate, prevent,
          or take action regarding potential violations of our policies, suspected fraud, situations
          involving potential threats to the safety of any person and illegal activities, or as
          evidence in litigation in which we are involved. &nbsp;
        </li>
      </ul>
      <ul>
        <li>
          <b>Vendors, Consultants, and Other Third-Party Service Providers.</b>
          We may share your data with third party vendors, service providers, contractors or agents
          who perform services for us or on our behalf and require access to such information to do
          that work. Examples include: payment processing, data analysis, email delivery, hosting
          services, customer service and marketing efforts. We may allow selected third parties to
          use tracking technology on the Apps, which will enable them to collect data about how you
          interact with the Apps over time. This information may be used to, among other things,
          analyze and track data, determine the popularity of certain content and better understand
          online activity. Unless described in this Policy, we do not share, sell, rent or trade any
          of your information with third parties for their promotional purposes. &nbsp;&nbsp;&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          <b>Business Transfers.</b>
          We may share or transfer your information in connection with, or during negotiations of,
          any merger, sale of company assets, financing, or acquisition of all or a portion of our
          business to another company. &nbsp;
        </li>
      </ul>
      <ul>
        <li>
          <b>Third-Party Advertisers</b>. We may use third-party advertising companies to serve ads
          when you visit the Apps. These companies may use information about your visits to our
          Website(s) and other websites that are contained in web cookies and other tracking
          technologies in order to provide advertisements about goods and services of interest to
          you. &nbsp;&nbsp;&nbsp;&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          <b>Affiliates.</b>
          We may share your information with our affiliates, in which case we will require those
          affiliates to honor this privacy policy. Affiliates include our parent company and any
          subsidiaries, joint venture partners or other companies that we control or that are under
          common control with us. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          <b>Business Partners.</b>
          We may share your information with our business partners to offer you certain products,
          services or promotions. &nbsp;&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          <b>With your Consent.</b>
          We may disclose your personal information for any other purpose with your consent.
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </li>
      </ul>
      <ol start="4">
        <li>
          <b> HOW LONG DO WE KEEP YOUR INFORMATION? &nbsp;</b>
        </li>
      </ol>
      <p>
        <i>
          In Short: We keep your information for as long as necessary to fulfill the purposes
          outlined in this privacy policy unless otherwise required by law.
        </i>
      </p>
      <p>
        We will only keep your personal information for as long as it is necessary for the purposes
        set out in this privacy policy, unless a longer retention period is required or permitted by
        law (such as tax, accounting or other legal requirements). No purpose in this policy will
        require us keeping your personal information for longer than 2 years past the termination of
        the user’s account.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your personal information, we
        will either delete or anonymize it, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will securely store your
        personal information and isolate it from any further processing until deletion is possible.
      </p>
      <ol start="5">
        <li>
          <b> HOW DO WE KEEP YOUR INFORMATION SAFE? &nbsp;</b>
        </li>
      </ol>
      <p>
        <i>
          In Short: We aim to protect your personal information through a system of organizational
          and technical security measures.
        </i>
      </p>
      <p>
        We have implemented appropriate technical and organizational security measures designed to
        protect the security of any personal information we process. However, please also remember
        that we cannot guarantee that the internet itself is 100% secure. Although we will do our
        best to protect your personal information, transmission of personal information to and from
        our Apps is at your own risk. You should only access the services within a secure
        environment.
      </p>
      <ol start="6">
        <li>
          <b> DO WE COLLECT INFORMATION FROM MINORS? &nbsp;</b>
        </li>
      </ol>
      <p>
        <i>
          In Short: We do not knowingly collect data from or market to children under 18 years of
          age.
        </i>
      </p>
      <p>
        {`We do not knowingly solicit data from or market to children under 18 years of age. By using
        the Apps, you represent that you are at least 18 or that you are the parent or guardian of
        such a minor and consent to such minor dependent’s use of the Apps. If we learn that
        personal information from users less than 18 years of age has been collected, we will
        deactivate the account and take reasonable measures to promptly delete such data from our
        records. If you become aware of any data we have collected from children under age 18,
        please contact us at `}
        <a href={`mailto:${SiteConfig.company.supportEmail}`} target="_top">
          {SiteConfig.company.supportEmail}
        </a>
        .
      </p>
      <ol start="7">
        <li>
          <b> WHAT ARE YOUR PRIVACY RIGHTS? &nbsp;</b>
        </li>
      </ol>
      <p>
        <i>
          In Short: In some regions, such as the European Economic Area, you have rights that allow
          you greater access to and control over your personal information. You may review, change,
          or terminate your account at any time.
        </i>
      </p>
      <p>
        In some regions (like the European Economic Area), you have certain rights under applicable
        data protection laws. These may include the right (i) to request access and obtain a copy of
        your personal information, (ii) to request rectification or erasure; (iii) to restrict the
        processing of your personal information; and (iv) if applicable, to data portability. In
        certain circumstances, you may also have the right to object to the processing of your
        personal information. To make such a request, please use the contact details (#contact)
        &nbsp;provided below. We will consider and act upon any request in accordance with
        applicable data protection laws.
      </p>
      <p>
        If we are relying on your consent to process your personal information, you have the right
        to withdraw your consent at any time. Please note however that this will not affect the
        lawfulness of the processing before its withdrawal. &nbsp;&nbsp;
      </p>
      <p>
        If you are resident in the European Economic Area and you believe we are unlawfully
        processing your personal information, you also have the right to complain to your local data
        protection supervisory authority. You can find their contact details here:
      </p>
      <p>
        <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          target="_blank"
          rel="noreferrer noopener"
        >
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
      </p>
      <p>
        <b>Account Information</b>
      </p>
      <p>
        If you would at any time like to review or change the information in your account or
        terminate your account, you can: &nbsp;
      </p>
      <ul>
        <li>Contact us using the contact information provided. &nbsp;&nbsp;</li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or delete your account and
        information from our active databases. However, some information may be retained in our
        files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our
        Terms of Use and/or comply with legal requirements. &nbsp;&nbsp;&nbsp;
      </p>
      <p>
        <b>Opting out of email marketing</b>: You can unsubscribe from our marketing email list at
        any time by clicking on the unsubscribe link in the emails that we send or by contacting us
        using the details provided below. You will then be removed from the marketing email list –
        however, we will still need to send you service-related emails that are necessary for the
        administration and use of your account. To otherwise opt-out, you may: &nbsp;
      </p>
      <ul>
        <li>Contact us using the contact information provided. &nbsp;&nbsp;</li>
      </ul>
      <ol start="8">
        <li>
          <b> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b>
          &nbsp;
        </li>
      </ol>
      <p>
        <i>
          In Short: Yes, if you are a resident of California, you are granted specific rights
          regarding access to your personal information.
        </i>
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our
        users who are California residents to request and obtain from us, once a year and free of
        charge, information about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all third parties with
        which we shared personal information in the immediately preceding calendar year. If you are
        a California resident and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a registered account with
        the Apps, you have the right to request removal of unwanted data that you publicly post on
        the Apps. To request removal of such data, please contact us using the contact information
        provided below, and include the email address associated with your account and a statement
        that you reside in California. We will make sure the data is not publicly displayed on the
        Apps, but please be aware that the data may not be completely or comprehensively removed
        from our systems.
      </p>
      <ol start="9">
        <li>
          <b> DO WE MAKE UPDATES TO THIS POLICY? &nbsp;</b>
        </li>
      </ol>
      <p>
        <i>
          In Short: Yes, we will update this policy as necessary to stay compliant with relevant
          laws.
        </i>
      </p>
      <p>
        We may update this privacy policy from time to time. The updated version will be indicated
        by an updated “Revised” date and the updated version will be effective as soon as it is
        accessible. If we make material changes to this privacy policy, we may notify you either by
        prominently posting a notice of such changes or by directly sending you a notification. We
        encourage you to review this privacy policy frequently to be informed of how we are
        protecting your information.
      </p>
      <ol start="10">
        <li>
          <b> HOW CAN YOU CONTACT US ABOUT THIS POLICY? &nbsp;</b>
        </li>
      </ol>
      <p>
        {`If you have questions or comments about this policy, you may email us at `}
        <a href={`mailto:${SiteConfig.company.supportEmail}`} target="_top">
          {SiteConfig.company.supportEmail}
        </a>
        .
      </p>
      <p>
        {`If you have any further questions or comments about us or our policies, email us at `}
        <a href={`mailto:${SiteConfig.company.supportEmail}`} target="_top">
          {SiteConfig.company.supportEmail}
        </a>
        .
      </p>
    </div>
  );
};

export default Privacy;
