import { VIDEOS_FETCHED } from 'Members/actions';

export default (videos, action) => {
  switch (action.type) {
    case VIDEOS_FETCHED:
      return action.data;
    default:
      return videos;
  }
};
