import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ProtectedRoute } from 'Members/utils';
import {
  DASHBOARD_ROUTES,
  VIDEO_ROUTES,
  PHOTO_ROUTES,
  GIRLS_ROUTES,
  SETTINGS_ROUTES,
  DEVICE_AUTH_ROUTES,
} from 'Members/const';
import { Header } from 'Members/components/layout';
import { AuthModal } from 'Members/components/Auth';
import Dashboard from './Dashboard';
import Video from './Video';
import Photos from './Photos';
import Girls from './Girl';
import Settings from './Settings';
import DeviceAuth from './DeviceAuth';

const Main = () => {
  return (
    <Fragment>
      <Header
        dashboardRoutes={DASHBOARD_ROUTES}
        photoRoutes={PHOTO_ROUTES}
        girlsRoutes={GIRLS_ROUTES}
        settingsRoutes={SETTINGS_ROUTES}
      />

      <Switch>
        <Route path={DASHBOARD_ROUTES} component={Dashboard} exact />
        <Route path={VIDEO_ROUTES} component={Video} exact />
        <Route path={PHOTO_ROUTES} component={Photos} exact />
        <Route path={GIRLS_ROUTES} component={Girls} exact />
        <ProtectedRoute path={SETTINGS_ROUTES} component={Settings} exact authenticated />
        <ProtectedRoute path={DEVICE_AUTH_ROUTES} component={DeviceAuth} exact authenticated />
      </Switch>

      <AuthModal />
    </Fragment>
  );
};

export default Main;
