import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppState } from 'Members/hooks';
import { getSubscriptions, cancelSubscription, showConfirmDialog } from 'Members/actions';
import { AppNavLink } from 'Members/utils';
import { Routes } from 'Members/const';
import { subscriptionStatusDescription } from 'Members/utils/SubscriptionHelper';
import InlineAlert from 'Members/components/InlineAlert';
import SiteConfig from 'site_config';

const freeProductNames = subscriptions => {
  if (!subscriptions && !subscriptions.length) return '';

  return subscriptions
    .filter(({ free }) => free)
    .map(({ productName }) => productName)
    .join(', ');
};

const SubscriptionRow = ({
  productName,
  activeSince,
  expireAt,
  status,
  cancellable,
  onCancelClick,
  isCancelling,
  primary,
  showSeparator,
}) => (
  <Fragment>
    <div className="row my-4">
      <div className="col-sm-9">
        <p className="h6 font-weight-bold p-0 m-0">{productName}</p>
        <p className="m-sm-0">{subscriptionStatusDescription({ activeSince, expireAt, status })}</p>
      </div>

      <div className="col-sm-2">
        {cancellable && (
          <button
            type="button"
            className="btn btn-sm btn-primary text-uppercase rounded shadow"
            onClick={onCancelClick}
            disabled={isCancelling}
          >
            Cancel Subscription
            {isCancelling && <FontAwesomeIcon icon={['fal', 'spinner-third']} spin />}
          </button>
        )}
      </div>
    </div>
    {primary && showSeparator && <hr className="col-11 mx-0" />}
  </Fragment>
);

const Billing = () => {
  const [, dispatch] = useAppState();
  const [subscriptions, setSubscriptions] = useState([]);
  const [error, setError] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const primarySubscription = subscriptions.find(subscription => subscription.primary);
  const hasPrimarySubscription = !!primarySubscription;

  // Show primary subscription first and then secondary subscriptions sorted by date.
  const onSubscriptionsFetched = data => {
    setSubscriptions(
      (data || []).sort((a, b) => {
        return b.primary - a.primary || new Date(b.activeSince) - new Date(a.activeSince);
      })
    );
  };

  const onSubscriptionCancelled = cancelledSubscription => {
    setSubscriptions(
      subscriptions.filter(subscription => subscription.id !== cancelledSubscription.id)
    );
  };

  const onCancelSubscription = ({ id, productName, primary }) => () => {
    const primaryText = `Are you sure you want to remove your access to ${productName}?`;
    const secondaryText = `By cancelling your ${SiteConfig.displayName} membership you will also lose access to`;
    const text =
      primary && subscriptions.length > 1
        ? `${primaryText} ${secondaryText} ${freeProductNames(subscriptions)}.`
        : primaryText;

    showConfirmDialog(dispatch, {
      text,
      title: 'Unsubscribe',
      onSubmit: async isConfirmed => {
        if (!isConfirmed) return;

        setIsCancelling(true);

        const message = await cancelSubscription(onSubscriptionCancelled, id);

        if (message) setError(message);
        setIsCancelling(false);
      },
    });
  };

  useEffect(() => {
    (async () => {
      const message = await getSubscriptions(onSubscriptionsFetched);

      if (message) setError(message);
      setIsLoading(false);
    })();
  }, []);

  return (
    <div className="billing-tab">
      {isLoading && (
        <div className="text-center">
          <FontAwesomeIcon size="6x" icon={['fal', 'spinner-third']} spin />
        </div>
      )}

      {error && (
        <InlineAlert
          icon={{ icon: ['fal', 'exclamation-triangle'] }}
          type="danger"
          message={error}
        />
      )}

      {!isLoading && <h3 className="mb-4">Subscriptions</h3>}

      {!isLoading && !hasPrimarySubscription && (
        <Fragment>
          <div className="row my-4">
            <div className="col-11">
              {`You have no subscription to ${SiteConfig.displayName}.`}
              <AppNavLink to={Routes.signupPayment()} noscroll>
                &nbsp;Upgrade&nbsp;
              </AppNavLink>
              to get full access to nude party videos, pranks & more!
            </div>
          </div>

          {subscriptions.length > 0 && <hr className="col-11 mx-0" />}
        </Fragment>
      )}

      {subscriptions.map(subscription => (
        <SubscriptionRow
          key={subscription.id}
          onCancelClick={onCancelSubscription(subscription)}
          isCancelling={isCancelling}
          showSeparator={subscriptions.length > 1}
          {...subscription}
        />
      ))}
    </div>
  );
};

SubscriptionRow.propTypes = {
  productName: PropTypes.string.isRequired,
  activeSince: PropTypes.string,
  expireAt: PropTypes.string,
  status: PropTypes.string,
  cancellable: PropTypes.bool,
  onCancelClick: PropTypes.func.isRequired,
  isCancelling: PropTypes.bool.isRequired,
  primary: PropTypes.bool.isRequired,
  showSeparator: PropTypes.bool.isRequired,
};

export default Billing;
