import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// The video has no audio. However FYI there are issues with `muted` in React:
// https://github.com/facebook/react/issues/10389
// https://github.com/testing-library/react-testing-library/issues/470
const BackgroundVideo = ({ src, children }) => {
  const [isLoaded, setIsLoaded] = useState(!src);
  const onVideoCanPlay = () => setIsLoaded(true);

  return (
    <div className="background-video">
      {src && <video autoPlay loop playsInline muted src={src} onCanPlay={onVideoCanPlay} />}
      {isLoaded && children}
      {!isLoaded && <FontAwesomeIcon icon={['fal', 'spinner-third']} size="3x" spin />}
    </div>
  );
};

BackgroundVideo.propTypes = {
  src: PropTypes.string,
};

export default BackgroundVideo;
