import React from 'react';
import SiteConfig from 'site_config';

const CopyrightAgent = () => {
  return (
    <div className="extra-copyright-agent container mb-5 mt-4">
      <h2>
        <b>Procedure for Making Claims of Copyright Infringement</b>
      </h2>
      <p>
        {`If you believe that your work has been copied on the ${SiteConfig.displayName} website
        in a way that constitutes copyright infringement, please notify our `}
        <a href={`mailto:${SiteConfig.company.copyrightEmail}`}>copyright agent</a> in writing, and
        include the following:
      </p>
      <ol>
        <li>
          Your physical or electronic signature, as the owner of an exclusive right that is
          allegedly infringed or as a person authorized to act on the owner’s behalf.
        </li>
        <li>
          Identification of the copyrighted work claimed to have been infringed, or, if multiple
          copyrighted works at our site are covered by a single notification, a representative list
          of such works.
        </li>
        <li>
          Identification of the material that is claimed to be infringing, or to be the subject of
          infringing activity and that is to be removed, or access to which is to be disabled, and
          information reasonably sufficient to permit us to locate the material.&nbsp;
          <b>Providing URLs in the body of an email is the best way to help us locate</b>
          <b>content quickly.</b>
        </li>
        <li>
          Information reasonably sufficient to permit us to contact you, such as your address,
          telephone number, or email address.
        </li>
        <li>
          A statement that you have a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent, or the law.
        </li>
        <li>
          A statement that the information in the notification is accurate, and under penalty of
          perjury, that you are the owner of an exclusive right that is allegedly infringed or a
          person authorized to act on the owner’s behalf.
        </li>
      </ol>
      <p>Such notices should be sent to our Copyright Agent as follows:</p>
      <address>
        DMCA Copyright Agent/{SiteConfig.displayName} <br />
        1288 N Hermitage Ave #4 <br />
        Chicago, IL 60622 <br />
        ph: 773.269.3400 <br />
        email: {SiteConfig.company.copyrightEmail}
      </address>
      <p>
        Please note that this procedure is exclusively for notifying us that your copyrighted
        material has been infringed.
      </p>
      <p>
        Please also note that under Section 512(f) of the Copyright Act, any person who knowingly
        materially misrepresents that material or activity is infringing may be subject to
        liability.
      </p>
      <h5>
        <b>Counter-Notification</b>
      </h5>
      <p>
        If you believe that material you posted has been removed in error, you should send our
        Copyright Agent, using the contact information listed above, a counter notice that includes
        the following:
      </p>
      <ol>
        <li>You physical or electronic signature.</li>
        <li>
          Identification of the material that has been removed or to which access has been disabled
          and the location at which the material appeared before it was removed or access to it was
          disabled.
        </li>
        <li>
          A statement under penalty of perjury that you have a good faith belief that the material
          was removed or disabled as a result of mistake or misidentification.
        </li>
        <li>
          Your name, address, and telephone number, and a statement that you consent to the
          jurisdiction of Federal District Court for the judicial district in which your address is
          located, or if your address is outside of the United States, for any judicial district in
          which our offices may be found, and that you will accept service of process from the
          person who provided notification of copyright infringement, or an agent of such person.
        </li>
      </ol>
      <p>
        Please note that under Section 512(f) of the Copyright Act, any person who knowingly
        materially misrepresents that material or activity was removed or disabled by mistake or
        misidentification may be subject to liability. Please also be advised that we enforce a
        policy that provides for the termination in appropriate circumstances of subscribers who are
        repeat infringers.
      </p>
    </div>
  );
};

export default CopyrightAgent;
