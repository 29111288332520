import { api } from 'Members/utils';
import { VIDEO_COMMENT_SUBMIT, VIDEO_COMMENT_REPLY } from './types';
import { alertRequestError } from './common';

export const replyVideoComment = async (dispatch, replyId) => {
  dispatch({ type: VIDEO_COMMENT_REPLY, data: replyId });
};

export const submitVideoComment = async (dispatch, { videoId, rootIndex, ...params }) => {
  try {
    const { data } = await api.createVideoComment(videoId, params);
    dispatch({ type: VIDEO_COMMENT_SUBMIT, data: { comment: data, rootIndex } });
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};
