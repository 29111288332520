import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppState, useForm } from 'Members/hooks';
import { submitSignupForm } from 'Members/actions';
import { Routes } from 'Members/const';
import { validateSignupForm } from './Form';
import { useHistory } from 'react-router-dom';

const Signup = ({ loginRoute, subTitle, submitAction, className }) => {
  const [, dispatch] = useAppState();
  const history = useHistory();

  const { values, edits, errors, isValid, onChange, onSubmit } = useForm(
    submitAction.bind(this, dispatch),
    validateSignupForm,
    { emailable: true }
  );

  useEffect(() => {
    if (window.location.pathname === '/tour/signup') {
      history.push(Routes.root());
    }
  }, [history]);

  return null;
};

Signup.defaultProps = {
  loginRoute: Routes.login(),
  submitAction: submitSignupForm,
};

Signup.propTypes = {
  loginRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.string,
  submitAction: PropTypes.func,
  className: PropTypes.string,
};

export default Signup;
