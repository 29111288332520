import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import userAvatar from 'images/user.png';
import vitalyAvatar from 'images/vitaly.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RequireUserType } from 'Members/utils';
import ReplyForm from './ReplyForm';

const Comment = ({
  id,
  username,
  body,
  createdAt,
  children,
  videoId,
  replyId,
  root,
  rootIndex,
  onReply,
}) => {
  const isReplying = replyId === id;
  const isVitaly = username === 'vitaly';
  const avatar = isVitaly ? vitalyAvatar : userAvatar;
  const commentClassNames = classNames('comment', { root, child: !root });

  return (
    <div className={commentClassNames}>
      <div className="comment-avatar">
        <img className="img-fluid" src={avatar} />
      </div>

      <div className="comment-content">
        <div className="comment-header font-weight-bold">
          <span className="comment-username text-primary">{username}</span>
          {isVitaly && <FontAwesomeIcon icon={['fal', 'check-circle']} />}
          <span className="comment-date text-muted">{` · ${createdAt}`}</span>
        </div>

        <div className="comment-body">{body}</div>

        <RequireUserType premium>
          <div className="comment-reply">
            <div className="comment-reply-button" onClick={() => onReply(id)}>
              <FontAwesomeIcon icon={['fal', 'paper-plane']} />
              <span>Reply</span>
            </div>
          </div>

          {isReplying && (
            <ReplyForm videoId={videoId} commentId={replyId} rootIndex={rootIndex} root={root} />
          )}
        </RequireUserType>

        <div className="comment-children">
          {root &&
            children.map(comment => (
              <Comment
                {...comment}
                key={comment.id}
                videoId={videoId}
                replyId={replyId}
                rootIndex={rootIndex}
                onReply={onReply}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

Comment.propTypes = {
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.object),
  videoId: PropTypes.number.isRequired,
  replyId: PropTypes.number,
  root: PropTypes.bool,
  rootIndex: PropTypes.number,
  onReply: PropTypes.func.isRequired,
};

Comment.defaultProps = {
  children: [],
  replyId: null,
  root: false,
  rootIndex: null,
};

export default Comment;
