import {
  GALLERY_VIEWER_SHOW,
  GALLERY_VIEWER_HIDE,
  GALLERY_VIEWER_NEXT,
  GALLERY_VIEWER_PREV,
} from 'Members/actions';

export default (galleryViewer, action) => {
  switch (action.type) {
    case GALLERY_VIEWER_SHOW: {
      return { ...galleryViewer, ...action.data, isVisible: true };
    }
    case GALLERY_VIEWER_HIDE: {
      return { ...galleryViewer, isVisible: false };
    }
    case GALLERY_VIEWER_NEXT: {
      const { galleries = [], galleryIndex = 0 } = galleryViewer || {};
      return { ...galleryViewer, galleryIndex: Math.min(galleryIndex + 1, galleries.length - 1) };
    }
    case GALLERY_VIEWER_PREV: {
      const { galleryIndex = 0 } = galleryViewer || {};
      return { ...galleryViewer, galleryIndex: Math.max(galleryIndex - 1, 0) };
    }
    default:
      return galleryViewer;
  }
};
