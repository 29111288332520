/**
 * We mainly need it for backward compatibility.
 * We want old auth routes to be properly redirected
 * to the corresponding modals on the home page.
 */

import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAppState, handleAuthRouteModal } from 'Members/hooks';
import { showModal } from 'Members/actions';
import { Routes } from 'Members/const';

const AuthModalHandler = () => {
  const [, dispatch] = useAppState();
  const { modal, search } = handleAuthRouteModal();

  showModal(dispatch, modal);

  return <Redirect to={{ pathname: Routes.root(), search }} />;
};

export default AuthModalHandler;
