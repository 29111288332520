import { api, recaptcha } from 'Members/utils';

import { AUTH_LOGIN, AUTH_SIGNUP, AUTH_LOGOUT } from './types';
import { alertRequestSuccess, alertRequestError } from './common';
import { resetDeviceAuthStatus } from './device';
import { showPaymentModal } from './modals';

export const submitSignupForm = async (dispatch, params) => {
  try {
    const { data } = await api.signup({ customer: params });
    dispatch({ type: AUTH_SIGNUP, data });
    showPaymentModal(dispatch);
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};

export const submitLoginForm = async (dispatch, params) => {
  try {
    const recaptchaToken = await recaptcha.execute('login');
    const { data } = await api.login({ customer: params, recaptchaToken });

    if (data.reload) window.location.reload();

    dispatch({ type: AUTH_LOGIN, data });
    return false;
  } catch ({ message }) {
    return { type: 'danger', message };
  }
};

/* Receive password reset instructions */
export const submitForgotPasswordForm = async (dispatch, params) => {
  try {
    const recaptchaToken = await recaptcha.execute('forgotpassword');
    await api.requestPasswordRecovery({ customer: params, recaptchaToken });
    return { type: 'success', message: 'Please check your e-mail for instructions.' };
  } catch ({ message }) {
    return { type: 'danger', message };
  }
};

/* Create a new password */
export const submitPasswordResetForm = async (dispatch, params) => {
  try {
    await api.setNewPasswordFromRecovery({ customer: params });
    alertRequestSuccess(dispatch, 'Your password has been reset.');
    return { type: 'success', message: 'Your password has been reset.' };
  } catch ({ message }) {
    return { type: 'danger', message };
  }
};

export const clickLogoutLink = async dispatch => {
  try {
    const { data } = await api.logout();

    if (data.reload) window.location.reload();

    dispatch({ type: AUTH_LOGOUT });
    resetDeviceAuthStatus(dispatch);
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};

export const getSubscriptions = async dispatch => {
  try {
    const { data } = await api.getSubscriptions();
    dispatch(data);
    return false;
  } catch ({ message }) {
    return message;
  }
};

export const cancelSubscription = async (dispatch, id) => {
  try {
    const { data } = await api.cancelSubscription(id);
    dispatch(data);
    return false;
  } catch ({ message }) {
    return message;
  }
};
