import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AppLink } from 'Members/utils';
import { Routes } from 'Members/const';
import { Logo } from 'Members/components/shared';
import Terms from './Terms';
import CopyrightAgent from './CopyrightAgent';
import Privacy from './Privacy';
import Compliance from './Compliance';
import Refunds from './Refunds';

const Extra = () => {
  return (
    <main className="extra-page">
      <nav className="extra-navbar">
        <AppLink to={Routes.root()}>
          <Logo />
        </AppLink>
      </nav>

      <Switch>
        <Route path={Routes.terms()} exact component={Terms} />
        <Route path={Routes.privacy()} exact component={Privacy} />
        <Route path={Routes.compliance()} exact component={Compliance} />
        <Route path={Routes.refunds()} exact component={Refunds} />
        <Route path={Routes.copyrightAgent()} exact component={CopyrightAgent} />
      </Switch>
    </main>
  );
};

export default Extra;
