import SiteConfig from 'site_config';

export const IsProduction = new RegExp(SiteConfig.siteDomain, 'i').test(window.location);
export const IsDevelopment = !IsProduction;
export const IsDebug = /_skdebug=1/i.test(window.location.search);
export const GATrackingId = IsProduction ? 'UA-143788883-1' : 'UA-143788883-2';
export const GOContainerId = IsProduction ? 'GTM-T83QGF4' : 'GTM-5XCT3VH';

export const PRODUCTION_ENV = 'production';
export const ANIMATION_DURATION = 600; // ms
export const LOGO_PATH = require.context('images/logo', false, /\.png$/)(
  `./${SiteConfig.siteName.toLowerCase()}.png`
);

export const ModalName = Object.freeze({
  access: 'access',
  login: 'login',
  signup: 'signup',
  payment: 'payment',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  oneClick: 'one-click',
});

export const Messages = Object.freeze({
  girlPurchaseCta: `Join ${SiteConfig.displayName} through this page and get all of my most private and revealing videos and galleries for FREE!`,
});

export * from './routes';
export * from './split_tests';
