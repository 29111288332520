import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AppLink } from 'Members/utils';

const TagLink = ({ to, className, children }) => {
  const linkCss = classNames('btn btn-tag', className);

  return to && children ? (
    <AppLink className={linkCss} to={to}>
      {children}
    </AppLink>
  ) : null;
};

TagLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
};

export default TagLink;
