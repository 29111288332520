import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeviceAuth } from 'Members/hooks';
import { Routes } from 'Members/const';

const RequireAuthorizedDevice = ({ children }) => {
  const { device, isGettingStatus } = useDeviceAuth();
  const shouldRedirect = !isGettingStatus && !device;
  const shouldRender = !isGettingStatus && device;

  return (
    <Fragment>
      {isGettingStatus && (
        <div className="text-center">
          <FontAwesomeIcon size="6x" icon={['fal', 'spinner-third']} spin />
        </div>
      )}

      {shouldRedirect && <Redirect to={Routes.deviceAuth()} />}
      {shouldRender && children}
    </Fragment>
  );
};

export default RequireAuthorizedDevice;
