import React from 'react';
import { AppLink, RequireUserType } from 'Members/utils';
import { Routes } from 'Members/const';
import { Logo, SocialMediaIcon } from 'Members/components/shared';
import SiteConfig from 'site_config';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="ma-container d-flex flex-column text-center p-4">
      <div className="footer-links-container d-flex flex-column align-items-center">
        <div className="footer-logo">
          <Logo className="img-fluid" />
        </div>

        <div className="footer-links d-flex flex-column flex-wrap justify-content-center flex-md-row text-uppercase">
          <RequireUserType authenticated>
            <AppLink to={Routes.accountSettings()} className="btn btn-nav">
              My Account
            </AppLink>
          </RequireUserType>

          <AppLink to={Routes.terms()} className="btn btn-nav">
            Terms
          </AppLink>
          <AppLink to={Routes.privacy()} className="btn btn-nav">
            Privacy Policy
          </AppLink>
          <AppLink to={Routes.copyrightAgent()} className="btn btn-nav">
            Copyright Agent
          </AppLink>

          {SiteConfig.company.affiliateUrl && (
            <a
              href={SiteConfig.company.affiliateUrl}
              className="btn btn-nav"
              target="_blank"
              rel="noopener noreferrer"
            >
              Affiliates
            </a>
          )}

          {SiteConfig.company.supportUrl && (
            <a href={SiteConfig.company.supportUrl} className="btn btn-nav">
              Contact Us
            </a>
          )}

          {SiteConfig.company.adsEmail && (
            <a href={`mailto:${SiteConfig.company.adsEmail}`} className="btn btn-nav">
              Advertising
            </a>
          )}

          {/* NOTE: Consider removing this because it's the same as Contact Us */}
          {SiteConfig.company.supportUrl && (
            <a
              href={SiteConfig.company.supportUrl}
              className="btn btn-nav"
              target="_blank"
              rel="noopener noreferrer"
            >
              Billing Support
            </a>
          )}
        </div>

        <div className="footer-social mb-4">
          <SocialMediaIcon name="instagram" url={SiteConfig.social.instagram} />
          <SocialMediaIcon name="twitter" url={SiteConfig.social.twitter} />
          <SocialMediaIcon name="facebook" url={SiteConfig.social.facebook} />
        </div>

        <AppLink to={Routes.compliance()} className="footer-compliance">
          18 U.S.C 2257 Record-Keeping Requirements Compliance Statement.
        </AppLink>

        <span className="footer-notice">
          All persons depicted herein were at least 18 years old at the time of the photography.
        </span>

        <span className="footer-copyright">
          {`© ${year} ${SiteConfig.displayName}. All Rights Reserved.`}
        </span>
      </div>

      {SiteConfig.camsEnabled && (
        <RequireUserType premium>
          <img
            src={Routes.camsPixel()}
            height="1"
            width="1"
            style={{ position: 'absolute', left: '-99999px' }}
          />
        </RequireUserType>
      )}
    </footer>
  );
};

export default Footer;
