import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppState } from 'Members/hooks';
import { fetchGirl, showAccessModal } from 'Members/actions';
import { GalleryViewer, LoadingProgress } from 'Members/components/shared';
import { GirlHelmet } from 'Members/utils';
import GirlDetails from './girl_details';
import GirlContent from './girl_content';

const Girl = () => {
  const { id: girlId } = useParams();
  const [{ user, girl }, dispatch] = useAppState();
  const isLoading = !girl || +girl.id !== +girlId;
  const onCtaButtonClick = () => showAccessModal(dispatch);

  useEffect(() => {
    fetchGirl(dispatch, girlId);
  }, [user]);

  return (
    <main className="girl-page ma-container">
      <GirlHelmet {...girl} />
      {isLoading && <LoadingProgress />}
      {!isLoading && <GirlDetails {...girl} onCtaButtonClick={onCtaButtonClick} />}
      {!isLoading && <GirlContent {...girl} onCtaButtonClick={onCtaButtonClick} />}
      <GalleryViewer />
    </main>
  );
};

export default Girl;
