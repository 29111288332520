import React, { useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { hideAlert } from 'Members/actions';
import { useAppState } from 'Members/hooks';

const DISPLAY_DURATION = 3000; // ms
const ANIMATION_DURATION = 500; // ms

let timeout;

const Alert = () => {
  const [{ alert = {} }, dispatch] = useAppState();
  const isVisible = !!alert.message;
  const type = isVisible ? alert.type || 'info' : 'none';
  const alertClassNames = `app-alert alert alert-${type}`;
  const key = `${type}${alert.message}`;

  useEffect(() => {
    if (isVisible) {
      window.clearTimeout(timeout);
      timeout = setTimeout(() => hideAlert(dispatch), DISPLAY_DURATION);
    }

    return () => window.clearTimeout(timeout);
  }, [alert.type, alert.message]);

  return (
    <TransitionGroup>
      <CSSTransition key={key} classNames="app-alert" timeout={ANIMATION_DURATION}>
        <div className={alertClassNames}>{alert.message}</div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Alert;
