import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'Members/hooks';
import { hasAccessToProduct } from 'Members/utils';

const RequireProductAccess = ({ productId, free, full, children }) => {
  const [{ user }] = useAppState();
  const hasAccess = hasAccessToProduct(user, productId);
  const showContent = (free && !hasAccess) || (full && hasAccess);

  return showContent ? <Fragment>{children}</Fragment> : null;
};

RequireProductAccess.propTypes = {
  productId: PropTypes.number,
  free: PropTypes.bool,
  full: PropTypes.bool,
};

RequireProductAccess.defaultProps = {
  free: false,
  full: false,
};

export default RequireProductAccess;
