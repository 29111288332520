import update from 'immutability-helper';
import { VIDEO_FETCHED, VIDEO_COMMENT_REPLY, VIDEO_COMMENT_SUBMIT } from 'Members/actions';

export default (video, action) => {
  switch (action.type) {
    case VIDEO_FETCHED:
      return action.data;
    case VIDEO_COMMENT_REPLY:
      return { ...video, replyId: action.data };
    case VIDEO_COMMENT_SUBMIT: {
      const { comment, rootIndex } = action.data;

      if (typeof rootIndex === 'number' && rootIndex >= 0) {
        return update(video, {
          comments: { [rootIndex]: { children: { $push: [comment] } } },
          replyId: { $set: null },
        });
      }

      return update(video, { comments: { $push: [comment] }, replyId: { $set: null } });
    }
    default:
      return video;
  }
};
