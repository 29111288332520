import React from 'react';
import PropTypes from 'prop-types';
import { LazyImage } from 'Members/components/shared';
import { AppLink } from 'Members/utils';
import { Routes } from 'Members/const';

const FeaturedVideo = ({ id, slug, title, subheading, category, coverUrl }) => {
  const type = category === 'episodes' ? subheading : category;

  return (
    <AppLink to={Routes.video({ id, slug })} className="featured-video">
      <div className="featured-video-cover">
        <LazyImage src={coverUrl} />
        <div className="featured-video-background" />
      </div>

      <div className="featured-video-caption">
        <div className="featured-video-type" data-badge="new">
          {type}
        </div>

        <h1 className="featured-video-title">{title}</h1>

        <button className="btn btn-regular btn-lg">Watch Now &gt;&gt;</button>
      </div>
    </AppLink>
  );
};

FeaturedVideo.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  coverUrl: PropTypes.string.isRequired,
};

export default FeaturedVideo;
