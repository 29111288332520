import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routes } from 'Members/const';
import { AppNavLink, RequireAuthorizedDevice, RequireUserType } from 'Members/utils';
import Account from './Account';
import Billing from './Billing';

const Settings = () => {
  return (
    <main className="settings-page ma-container bg-section text-section-contrast">
      <RequireAuthorizedDevice>
        <div className="settings-container">
          <h3 className="settings-title">Settings</h3>

          <div className="settings-tabs">
            <AppNavLink
              to={Routes.accountSettings()}
              className="btn btn-tab btn-lg"
              replace
              noscroll
            >
              Account
            </AppNavLink>
            <RequireUserType authenticated>
              <AppNavLink
                to={Routes.accountBilling()}
                className="btn btn-tab btn-lg"
                replace
                noscroll
              >
                Billing
              </AppNavLink>
            </RequireUserType>
          </div>

          <Switch>
            <Route path={Routes.accountSettings()} exact component={Account} />
            <Route path={Routes.accountBilling()} exact component={Billing} />
          </Switch>
        </div>
      </RequireAuthorizedDevice>
    </main>
  );
};

export default Settings;
