import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import SiteConfig from 'site_config';

const GirlHelmet = ({ seoTitle }) =>
  seoTitle ? (
    <Helmet>
      <title>{`${seoTitle} - ${SiteConfig.displayName}`}</title>
    </Helmet>
  ) : null;

GirlHelmet.propTypes = {
  seoTitle: PropTypes.string,
};

export default GirlHelmet;
