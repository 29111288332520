import React from 'react';
import ReactDOM from 'react-dom';
import { hideModal } from 'Members/actions';
import { useAppState } from 'Members/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AuthModalBase = ({ children }) => {
  const [, dispatch] = useAppState();

  const onClose = () => {
    hideModal(dispatch);
  };

  return ReactDOM.createPortal(
    <div className="auth-modal-container" role="modal">
      <div className="auth-modal-overlay" onClick={onClose} />
      <div className="auth-modal">
        <div className="auth-modal-close" onClick={onClose}>
          <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
        </div>
        <div className="auth-modal-body">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default AuthModalBase;
