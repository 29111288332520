import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'Members/hooks';
import { alertRequestError } from 'Members/actions';
import { LoadingProgress } from 'Members/components/shared';
import InfiniteScroll from 'react-infinite-scroller';

const INITIAL_STATE = {
  content: [],
  meta: {},
};

const InfiniteContent = ({ fetch, children, ...rest }) => {
  const [{ user }, dispatch] = useAppState();
  const [{ content, meta }, setContentState] = useState(INITIAL_STATE);
  const isFetchingRef = useRef(false);
  const { page = 0, total = null } = meta;
  const hasMore = !total || page < total;

  const onContentFetched = ({ data: responseData, meta: responseMeta, error }) => {
    if (error) {
      alertRequestError(dispatch, error);
      return;
    }

    isFetchingRef.current = false;

    setContentState({
      content: content.concat(responseData || []),
      meta: responseMeta || {},
    });
  };

  const loadMore = nextPage => {
    if (!isFetchingRef.current) {
      isFetchingRef.current = true;
      fetch(onContentFetched, nextPage);
    }
  };

  // Refetch content when user's state changes.
  useEffect(() => {
    isFetchingRef.current = false;
    setContentState(INITIAL_STATE);
  }, [user]);

  return (
    <InfiniteScroll
      key={user}
      pageStart={0}
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<LoadingProgress key={`loading-${page}`} />}
      {...rest}
    >
      {children({ content, meta })}
    </InfiniteScroll>
  );
};

InfiniteContent.propTypes = {
  fetch: PropTypes.func.isRequired,
};

export default InfiniteContent;
