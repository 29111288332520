import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppState } from 'Members/hooks';
import { fetchGirl, showAccessModal, showLoginModal } from 'Members/actions';
import { LoadingProgress } from 'Members/components/shared';
import { AuthModal } from 'Members/components/Auth';
import { GirlHelmet } from 'Members/utils';
import GirlDetails from './girl_details';

const GirlTour = () => {
  const { id: girlId } = useParams();
  const [{ user, girl }, dispatch] = useAppState();
  const isLoading = !girl || +girl.id !== +girlId;
  const onCtaButtonClick = () => showAccessModal(dispatch);
  const onLoginButtonClick = () => showLoginModal(dispatch);

  useEffect(() => {
    fetchGirl(dispatch, girlId);
  }, [user]);

  return (
    <main className="girl-tour-page">
      <GirlHelmet {...girl} />
      {isLoading && <LoadingProgress />}
      {!isLoading && (
        <GirlDetails
          {...girl}
          onCtaButtonClick={onCtaButtonClick}
          onLoginButtonClick={onLoginButtonClick}
        />
      )}
      <AuthModal />
    </main>
  );
};

export default GirlTour;
