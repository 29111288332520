import userReducer from './user';
import videosReducer from './videos';
import videoReducer from './video';
import girlReducer from './girl';
import alertReducer from './alert';
import confirmReducer from './confirm';
import modalReducer from './modal';
import galleryViewerReducer from './gallery_viewer';
import errorsReducer from './errors';
import splitTestReducer from './split_test';
import deviceReducer from './device';

export default (
  {
    user,
    videos,
    video,
    girl,
    alert,
    confirm,
    modal,
    galleryViewer,
    errors,
    device,
    splitTest,
    appEnvironment,
    billingDescriptor,
    featuredCategories,
    restrictedApp,
  },
  action
) => ({
  user: userReducer(user, action),
  videos: videosReducer(videos, action),
  video: videoReducer(video, action),
  girl: girlReducer(girl, action),
  alert: alertReducer(alert, action),
  confirm: confirmReducer(confirm, action),
  modal: modalReducer(modal, action),
  galleryViewer: galleryViewerReducer(galleryViewer, action),
  errors: errorsReducer(errors, action),
  device: deviceReducer(device, action),
  splitTest: splitTestReducer(splitTest, action),
  appEnvironment,
  billingDescriptor,
  featuredCategories,
  restrictedApp,
});
