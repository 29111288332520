import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppState } from 'Members/hooks';
import { hideGalleryViewer, nextGalleryViewer, prevGalleryViewer } from 'Members/actions';
import GalleryViewerContent from './gallery_viewer_content';

const GalleryViewer = ({ caption }) => {
  const [{ galleryViewer }, dispatch] = useAppState();
  const { galleries, galleryIndex, isVisible } = galleryViewer || {};

  const onClose = () => hideGalleryViewer(dispatch);
  const onPrevious = () => prevGalleryViewer(dispatch);
  const onNext = () => nextGalleryViewer(dispatch);
  const handlers = { onPrevious, onNext };

  const previousGallery = galleries ? galleries[galleryIndex - 1] : null;
  const nextGallery = galleries ? galleries[galleryIndex + 1] : null;

  const hasPreviousGallery = !!previousGallery && !previousGallery.locked;
  const hasNextGallery = !!nextGallery && !nextGallery.locked;
  const extraProps = { hasPreviousGallery, hasNextGallery };

  // Handle keyboard shortcuts to navigate through content in the viewer.
  useEffect(() => {
    const onKeyPressed = ({ keyCode }) => {
      switch (keyCode) {
        case 37: // left
          if (hasPreviousGallery) onPrevious();
          break;
        case 39: // right
          if (hasNextGallery) onNext();
          break;
        case 27: // esc
          onClose();
          break;
        default:
      }
    };

    document.addEventListener('keydown', onKeyPressed);

    return () => document.removeEventListener('keydown', onKeyPressed);
  }, [isVisible, hasNextGallery, hasPreviousGallery]);

  if (!galleryViewer || !isVisible) return null;

  return ReactDOM.createPortal(
    <div className="gallery-viewer-modal-container">
      <div className="gallery-viewer-modal-close">
        <FontAwesomeIcon
          icon={['fal', 'times']}
          size="3x"
          className="clickable"
          onClick={onClose}
        />
      </div>
      <div className="gallery-viewer-modal-body">
        <GalleryViewerContent
          gallery={galleries[galleryIndex]}
          caption={caption}
          {...handlers}
          {...extraProps}
        />
      </div>
    </div>,
    document.body
  );
};

GalleryViewer.propTypes = {
  caption: PropTypes.elementType,
};

export default GalleryViewer;
