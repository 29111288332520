export default (selector, duration, delay = 0) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    const start = window.pageYOffset || document.documentElement.scrollTop;
    const diff = element && element.getBoundingClientRect ? element.getBoundingClientRect().top : 0;

    let startTimestamp;

    const step = timestamp => {
      if (!startTimestamp) startTimestamp = timestamp;

      const elapsed = timestamp - startTimestamp;
      const percent = Math.min(elapsed / duration, 1);

      window.scrollTo(0, start + diff * percent);

      if (elapsed < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, delay);
};
