import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LazyImage } from 'Members/components/shared';

const isVideo = src => /\.(mp4|m3u8)|vimeo/gi.test(src);
const isNested = src => Array.isArray(src);

const ThumbnailIcon = ({ src }) => {
  const icon = isNested(src) ? 'clone' : isVideo(src) ? 'play' : null; // eslint-disable-line

  if (!icon) return null;

  return <FontAwesomeIcon icon={['fas', icon]} size="2x" className="gallery-thumb-icon" />;
};

const GalleryThumbnail = ({
  className,
  captionClassName,
  onClick,
  description,
  thumbnailUrl,
  assetUrl,
}) => {
  const thumbnailCss = classNames('gallery-thumb', className);
  const captionCss = classNames('gallery-thumb-caption', captionClassName);

  return (
    <div className={thumbnailCss}>
      <div className="gallery-thumb-image" onClick={onClick}>
        <LazyImage src={thumbnailUrl} />
        <ThumbnailIcon src={assetUrl} />
      </div>
      {description && <div className={captionCss}>{description}</div>}
    </div>
  );
};

ThumbnailIcon.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

GalleryThumbnail.propTypes = {
  className: PropTypes.string,
  captionClassName: PropTypes.string,
  onClick: PropTypes.func,
  description: PropTypes.string,
  thumbnailUrl: PropTypes.string.isRequired,
  assetUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default GalleryThumbnail;
