import SiteConfig from 'site_config';

/** ********************
  PurchaseHelper is a group of functionality that allows up to build our
  join form with the correct text. Depending on if the package is a trial
  or yearly, monthly, weekly rebill -- we want the user to read the right
  set of data. The helper will be tested for the different cases. Make sure
  you update the tests and different cases come.
 ********************* */

/*
@function price

We're displaying the price to the customer.
- Display 0 / null as FREE
- Replace any even dollar amounts with a short string, $1.00 -> $1
*/
function price(string) {
  if (!string || string === '$0.00' || string === 0) {
    return 'FREE';
  }

  return string.toString().replace('.00', '');
}

/*
@private
@function timeSpan

Returns the relative timespan on a given day count.
For example, 365 is equal to a 'yr'
*/
function term(days) {
  switch (days) {
    case 30:
      return '/month';
    case 365:
      return '/year';
    default:
      return '';
  }
}

function period(days) {
  switch (days) {
    case 365:
      return 'Year';
    default:
      return `${days} Days`;
  }
}

function plural(number, string) {
  if (number > 1 || number === 0) {
    return `${string}s`;
  }

  return string;
}

/** ********************
  Exported Functionality
 ********************* */

export function MembershipType({ trial, introductory }) {
  if (trial === true || introductory === true) {
    return 'Trial Membership';
  }
  if (trial === false && introductory === false) {
    return 'All Access Membership';
  }
  return '';
}

export function InitialAmount({ initialAmount }) {
  return price(initialAmount);
}

export function InitialTerm({
  trial = false,
  introductory = false,
  lifetime = false,
  initialDays,
}) {
  if (lifetime) {
    return 'Lifetime';
  }
  if (trial) {
    return `${initialDays}-Day Trial`;
  }
  if (introductory) {
    return `${initialDays}-Day Intro`;
  }

  return term(initialDays);
}

export function RebillsDefined({ trial, lifetime, introductory, rebillDays }) {
  if (trial || introductory) {
    return '';
  }

  if (rebillDays === 30) {
    return 'Rebills Monthly';
  }
  if (rebillDays === 365) {
    return 'Rebills Annually';
  }
  if (lifetime) {
    return 'Never Rebills';
  }
  return `Rebills every ${rebillDays}-Days`;
}

export function RenewalFootnote({ rebillAmount, rebillDays }) {
  if (rebillDays && rebillAmount) {
    return `Renews at ${price(rebillAmount)} every ${period(rebillDays)} until cancelled.`;
  }
  if (rebillAmount === null || rebillAmount === 0 || rebillDays === null || rebillDays === 0) {
    return 'Never renews';
  }
  return '';
}

export function bundledPackageText(partnerPackages) {
  if (partnerPackages.length === 0) {
    return null;
  }

  if (partnerPackages.length === 1) {
    const { initialAmount, productDescription } = partnerPackages[0];
    const initial = price(initialAmount);
    return `+ ${initial} ${InitialTerm(partnerPackages[0])} of ${productDescription} †`;
  }

  return `+ ${partnerPackages.length} Other Sites †`;
}

export function BundledPackageFootnote({ partnerPackages }) {
  if (!partnerPackages || partnerPackages.length === 0) {
    return null;
  }

  const footnoteText = ({
    trial,
    initialAmount,
    initialDays,
    productDescription,
    rebillAmount,
    rebillDays,
  }) => {
    const initial = price(initialAmount);
    return `The ${initial} ${InitialTerm({
      trial,
      initialDays,
    })} of ${productDescription} is only available to new customers of ${productDescription}. ${RenewalFootnote(
      { rebillAmount, rebillDays }
    )} Cancellation of the ${productDescription} membership will not affect the membership to ${
      SiteConfig.displayName
    }.`;
  };

  return `† ${partnerPackages.map(footnoteText).join('')}`;
}

export function crossSaleText({ name, initialAmount, initialDays }) {
  const days = plural(initialDays, 'day');
  const initial = price(initialAmount);

  return `Get Access to ${name} for ${initial}. After ${initialDays} ${days}, the membership renews at the standard monthly rate. Cancel anytime. **`;
}

export function crossSaleSelectBoxText({ name, initialAmount, initialDays }) {
  const initial = price(initialAmount);
  const trial = true;

  return `Add a ${initial} ${InitialTerm({ trial, initialDays })} to ${name} **`;
}

export function amountInCents(amount) {
  if (typeof amount === 'string') {
    return amount.trim().replace('$', '') * 100;
  }

  if (typeof amount === 'number') {
    return amount * 100;
  }

  return 0;
}

export function monthlyPackage(billingGroup) {
  const packages = billingGroup && billingGroup.billingPackages ? billingGroup.billingPackages : [];

  return packages.find(({ initialDays }) => +initialDays === 30);
}

export function oneClickPurchaseText(productName, oneClickPackage) {
  const { initialAmount, initialDays, rebillDays } = oneClickPackage;
  const initialDaysText = plural(initialDays, 'day');
  const rebillDaysText = plural(rebillDays, 'day');
  const initialPrice = price(initialAmount);

  return `You will be charged ${initialPrice} for ${initialDays} ${initialDaysText} of access to ${productName}, recurring every ${rebillDays} ${rebillDaysText}. You may cancel this subscription at any time.`;
}
