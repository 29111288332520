import { createValidator, composeValidators, combineValidators, isRequired } from 'revalidate';

const isValidComment = createValidator(
  message => value => (value && !value.trim() ? message : undefined),
  'Invalid comment message'
);

export const validateReplyForm = combineValidators({
  body: composeValidators(isRequired('Comment'), isValidComment)(),
});
