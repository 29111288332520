import React from 'react';
import PropTypes from 'prop-types';
import { LazyImage } from 'Members/components/shared';
import { LOGO_PATH } from 'Members/const';

const Logo = ({ lazy, ...rest }) => <LazyImage src={LOGO_PATH} {...rest} />;

Logo.propTypes = {
  lazy: PropTypes.bool,
};

Logo.defaultProps = {
  lazy: false,
};

export default Logo;
