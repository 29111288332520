import React, { useState, useEffect, useRef } from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingProgress } from 'Members/components/shared';
import { tracker } from 'Members/utils';
import { showAccessModal } from 'Members/actions';
import { useAppState } from 'Members/hooks';
import { IsDebug } from 'Members/const';

const CONTROLS_MODES = {
  full: ['play-large', 'play', 'progress', 'mute', 'volume', 'settings', 'fullscreen'],
  minimal: ['play-large', 'mute', 'settings', 'fullscreen'],
  none: ['play-large'],
};

const setupTracking = ({ player, dispatch, id }) => {
  if (!player || !id) return;

  // Do not track play not at the beginning of the video.
  const trackVideoPlay = () => {
    if (player.currentTime < 1) tracker.videoPlay(id);
  };

  const trackVideoSeeked = () => {
    const percent = player.currentTime / player.duration;
    tracker.videoSeeked(id, percent);
  };

  const trackVideoEnded = () => {
    tracker.videoEnded(id);

    // Show proper modal when teaser finishes
    showAccessModal(dispatch);
  };

  player.on('play', trackVideoPlay);
  player.on('seeked', trackVideoSeeked);
  player.on('ended', trackVideoEnded);
};

const setupQualityLevels = hls => ({
  default: hls.levels[hls.levels.length - 1].height,
  options: hls.levels.map(level => level.height),
  forced: true,
  onChange: quality => {
    hls.levels.forEach((level, index) => {
      if (level.height === quality) {
        hls.currentLevel = index; // eslint-disable-line
      }
    });
  },
});

const VitalyPlayer = ({ id, src, poster, controls, autoplay }) => {
  const [, dispatch] = useAppState();
  const [isInitialized, setIsInitialized] = useState(false);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const hlsRef = useRef(null);
  const isEmbed = /vimeo|youtu?be/.test(src);
  const isHls = Hls.isSupported() && /\.m3u8/.test(src);
  const playerCss = classNames('vitaly-player', { '--initializing': !isInitialized });

  useEffect(() => {
    const config = {
      autoplay,
      controls: CONTROLS_MODES[controls] || CONTROLS_MODES.full,
      settings: ['quality'],
      debug: IsDebug,
    };

    // In case of HLS wait until a manifest is available.
    // When it's parsed setup quality levels options.
    const whenConfigIsReady = new Promise((resolve, reject) => {
      if (isHls) {
        hlsRef.current = new Hls();

        hlsRef.current.loadSource(src);
        hlsRef.current.attachMedia(videoRef.current);

        hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
          config.quality = setupQualityLevels(hlsRef.current);
          resolve();
        });

        // TODO: Add better error handling.
        // See https://github.com/video-dev/hls.js/blob/master/docs/API.md#fifth-step-error-handling
        hlsRef.current.on(Hls.Events.ERROR, (event, data) => {
          reject(data);
        });
      } else {
        videoRef.current.src = src;
        resolve();
      }
    });

    (async () => {
      await whenConfigIsReady;
      playerRef.current = new Plyr(videoRef.current, config);
      setupTracking({ player: playerRef.current, id, dispatch });
      setIsInitialized(true);
    })();

    return () => {
      if (playerRef.current) playerRef.current.destroy();
      if (hlsRef.current) hlsRef.current.destroy();
    };
  }, [src]);

  return (
    <div className={playerCss}>
      {isEmbed ? (
        <div ref={videoRef} className="plyr__video-embed">
          <iframe src={src} allowFullScreen />
        </div>
      ) : (
        <video ref={videoRef} poster={poster} playsInline />
      )}
      <LoadingProgress />
    </div>
  );
};

VitalyPlayer.propTypes = {
  id: PropTypes.number,
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
  controls: PropTypes.oneOf(['none', 'minimal', 'full']),
  autoplay: PropTypes.bool,
};

VitalyPlayer.defaultProps = {
  controls: 'full',
  autoplay: false,
};

export default VitalyPlayer;
