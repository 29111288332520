import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InlineAlert = ({ type, message, icon }) => {
  return (
    <div className={`alert alert-${type}`}>
      {icon && <FontAwesomeIcon {...icon} style={{ marginRight: 5 }} />}
      {message}
    </div>
  );
};

InlineAlert.propTypes = {
  type: PropTypes.oneOf(['danger', 'info', 'warning', 'success']).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.object,
};

export default InlineAlert;
