import React from 'react';
import PropTypes from 'prop-types';
import { GirlThumbnail, ThumbnailsCarousel } from 'Members/components/shared';
import { AppLink } from 'Members/utils';
import { Routes } from 'Members/const';
import SiteConfig from 'site_config';

const GirlsCarousel = ({ girls }) => {
  if (!girls || !girls.length) return null;

  return (
    <div className="girls-carousel-container bg-base text-base-contrast py-5 mb-4">
      <h1 className="section-title mt-2">{SiteConfig.girls.name}</h1>

      <ThumbnailsCarousel
        key={girls.length}
        className="my-5"
        itemClassName="px-2"
        small={2}
        medium={4}
        large={6}
      >
        {girls.map(girl => (
          <GirlThumbnail key={girl.id} className="text-base-contrast" {...girl} />
        ))}
      </ThumbnailsCarousel>

      <AppLink
        className="btn btn-md btn-outline-light rounded-pill px-3 text-uppercase"
        to={Routes.girls()}
      >
        {`See All ${SiteConfig.girls.name}`}
      </AppLink>
    </div>
  );
};

GirlsCarousel.propTypes = {
  girls: PropTypes.array.isRequired,
};

export default GirlsCarousel;
