/**
 * A hook for device authorization status check. Provides a status value
 * and a boolean value indicating is it in progress to get a status.
 */

import { useState, useEffect } from 'react';
import { useAppState } from 'Members/hooks';
import { getDeviceAuthStatus } from 'Members/actions';

const useDeviceAuth = () => {
  const [{ device }, dispatch] = useAppState();
  const shouldAuth = typeof device === 'undefined';
  const [isGettingStatus, setIsGettingStatus] = useState(shouldAuth);

  useEffect(() => {
    (async () => {
      if (shouldAuth) {
        await getDeviceAuthStatus(dispatch);
        setIsGettingStatus(false);
      }
    })();
  }, [device]);

  return { device, isGettingStatus };
};

export default useDeviceAuth;
