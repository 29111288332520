import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'Members/hooks';
import { isPremium, isFreemium, isGuest, isAuthenticated } from 'Members/utils';

const RequireUserType = ({ children, guest, freemium, premium, authenticated }) => {
  const [{ user }] = useAppState();
  const isGuestValue = isGuest(user);
  const isAuthenticatedValue = isAuthenticated(user);
  const isPremiumValue = isPremium(user);
  const isFreemiumValue = isFreemium(user);
  const showContent =
    (guest && isGuestValue) ||
    (freemium && isFreemiumValue) ||
    (premium && isPremiumValue) ||
    (authenticated && isAuthenticatedValue);

  if (showContent) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
};

RequireUserType.propTypes = {
  guest: PropTypes.bool,
  freemium: PropTypes.bool,
  premium: PropTypes.bool,
  authenticated: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

RequireUserType.defaultProps = {
  guest: false,
  freemium: false,
  premium: false,
  authenticated: false,
};

export default RequireUserType;
