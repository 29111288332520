import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GalleryViewerAsset from './gallery_viewer_asset';

const DefaultCaption = ({ description }) => <p>{description}</p>;

const GalleryViewerContent = ({
  gallery,
  onPrevious,
  onNext,
  hasPreviousGallery,
  hasNextGallery,
  caption,
}) => {
  const leftNavButtonCss = classNames('clickable', { __hidden: !hasPreviousGallery });
  const rightNavButtonCss = classNames('clickable', { __hidden: !hasNextGallery });
  const Caption = caption || DefaultCaption;

  const LeftNavButton = (
    <FontAwesomeIcon
      icon={['fal', 'chevron-left']}
      size="3x"
      className={leftNavButtonCss}
      onClick={onPrevious}
    />
  );

  const RightNavButton = (
    <FontAwesomeIcon
      icon={['fal', 'chevron-right']}
      size="3x"
      className={rightNavButtonCss}
      onClick={onNext}
    />
  );

  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => document.body.classList.remove('modal-open');
  }, []);

  return (
    <Fragment>
      <div className="gallery-container">
        <span className="desktop-nav gallery-left-arrow">{LeftNavButton}</span>
        <div className="gallery-wrapper">
          <GalleryViewerAsset {...gallery} />
        </div>
        <span className="desktop-nav gallery-right-arrow">{RightNavButton}</span>
      </div>
      <div className="gallery-caption-container">
        <span className="mobile-nav gallery-left-arrow">{LeftNavButton}</span>
        <div className="gallery-caption">
          <Caption {...gallery} />
        </div>
        <span className="mobile-nav gallery-right-arrow">{RightNavButton}</span>
      </div>
    </Fragment>
  );
};

DefaultCaption.propTypes = {
  description: PropTypes.string,
};

GalleryViewerContent.propTypes = {
  gallery: PropTypes.object.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  hasPreviousGallery: PropTypes.bool.isRequired,
  hasNextGallery: PropTypes.bool.isRequired,
  caption: PropTypes.elementType,
};

export default GalleryViewerContent;
