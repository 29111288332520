import React from 'react';
import { Helmet } from 'react-helmet';
import { LOGO_PATH } from 'Members/const';
import SiteConfig from 'site_config';

const sameAs = Object.values(SiteConfig.social)
  .map(url => `"${url}"`)
  .join(',');

const HomeHelmet = () => (
  <Helmet>
    <title>Vitaly Uncensored (Vitalyzdtv): Sexy Nude Models, Pranks & Parties on Video</title>
    <meta
      name="description"
      content="The official website of world famous prankster & playboy Vitaly Zdorovetskiy. Watch wild, uncensored nude party videos, pranks & more!"
    />
    <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "${SiteConfig.displayName}",
          "url": "${SiteConfig.siteUrl}",
          "sameAs": [${sameAs}],
          "logo": "${SiteConfig.siteUrl}${LOGO_PATH}"
        }
      `}
    </script>
  </Helmet>
);

export default HomeHelmet;
