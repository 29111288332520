import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Routes } from 'Members/const';
import SiteConfig from 'site_config';

const VideoHelmet = ({ id, slug, heading, description }) =>
  id && (
    <Helmet>
      <title>{`${heading} - ${SiteConfig.displayName}`}</title>
      {description && <meta name="description" content={description} />}
      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "VideoPage",
          "name": "${heading}",
          "description: "${description || ''}",
          "url": "${SiteConfig.siteUrl}${Routes.video({ id, slug })}"
        }
      `}</script>
    </Helmet>
  );

VideoHelmet.defaultProps = {
  id: null,
  slug: '',
  heading: '',
  description: '',
};

VideoHelmet.propTypes = {
  id: PropTypes.number,
  slug: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
};

export default VideoHelmet;
