import React, { useEffect, useState } from 'react';
import { useAppState } from 'Members/hooks';
import { fetchVideos, fetchGirls, alertRequestError } from 'Members/actions';
import { Routes } from 'Members/const';

import {
  AppNavLink,
  scrollTo,
  getCategoryFromPathname,
  getVideoList,
  displayCategoryFilter,
  RequireUserType,
  HomeHelmet,
} from 'Members/utils';

import { LoadingProgress } from 'Members/components/shared';
import { InlineSignup } from 'Members/components/Auth';
import FeaturedVideo from './FeaturedVideo';
import CamsIframe from './cams_iframe';
import VideoSection from './videos_section';
import GirlsCarousel from './girls_carousel';

const CACHE = {
  girls: null,
};

const Dashboard = ({ location: { pathname } }) => {
  const [{ videos = [], featuredCategories = [] }, dispatch] = useAppState();
  const [girls, setGirls] = useState(CACHE.girls || []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const category = getCategoryFromPathname(pathname);
  const featuredVideo = videos[0];
  const filteredVideos = getVideoList(videos, category, featuredVideo);
  const onTabClick = () => scrollTo('.videos-categories', 300);

  const IFRAME_POSITION = (windowWidth > 991 ? 3 : 1) * 2;
  const topVideoSection = filteredVideos.slice(0, IFRAME_POSITION);
  const middleVideoSection = filteredVideos.slice(IFRAME_POSITION, IFRAME_POSITION * 2);
  const bottomVideoSection = filteredVideos.slice(IFRAME_POSITION * 2, filteredVideos.length);

  const onGirlsFetched = ({ data, error }) => {
    if (error) alertRequestError(dispatch, error);

    setGirls(data);
    CACHE.girls = data;
  };

  useEffect(() => {
    fetchVideos(dispatch);
  }, []);

  useEffect(() => {
    if (!CACHE.girls) fetchGirls(onGirlsFetched, { random: true });
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <main className="dashboard-page ma-container">
      {!category && <HomeHelmet />}

      <div className="featured-video-section">
        {featuredVideo ? <FeaturedVideo {...featuredVideo} /> : <LoadingProgress />}
      </div>

      <RequireUserType guest>
        <InlineSignup />
      </RequireUserType>

      <div className="videos-grid-container bg-section text-section-contrast">
        <div className="videos-categories">
          <AppNavLink
            to={Routes.root()}
            className="btn btn-tab btn-lg"
            onClick={onTabClick}
            replace
            noscroll
            exact
          >
            All Videos
          </AppNavLink>

          {featuredCategories.map(
            ({ slug, name }) =>
              displayCategoryFilter(videos, Routes.featuredCategory({ slug })) && (
                <AppNavLink
                  to={Routes.featuredCategory({ slug })}
                  className="btn btn-tab btn-lg"
                  onClick={onTabClick}
                  key={slug}
                  replace
                  noscroll
                >
                  {name}
                </AppNavLink>
              )
          )}
        </div>

        <VideoSection videos={topVideoSection} category={category} />

        <GirlsCarousel girls={girls} />

        <VideoSection videos={middleVideoSection} category={category} />

        <CamsIframe url={Routes.camsIframe()} />

        <VideoSection videos={bottomVideoSection} category={category} />
      </div>
    </main>
  );
};

export default Dashboard;
