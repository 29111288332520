import React from 'react';
import PropTypes from 'prop-types';
import { VideoThumbnail } from 'Members/components/shared';

const VideoSection = ({ videos, category }) => {
  return (
    <div className="videos-grid px-4">
      {videos.map(video => (
        <VideoThumbnail
          key={`${category}-${video.id}`}
          className="text-section-contrast"
          {...video}
        />
      ))}
    </div>
  );
};

VideoSection.propTypes = {
  videos: PropTypes.array.isRequired,
  category: PropTypes.string,
};

export default VideoSection;
