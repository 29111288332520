import axios from 'axios';
import humps from 'humps';
import { Routes } from 'Members/const';

class ApiError extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
  }
}

class Api {
  constructor({ baseUrl }) {
    this.baseUrl = baseUrl;

    this.instance = axios.create({
      baseURL: baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      transformResponse: [
        ...axios.defaults.transformResponse,
        this.camelizeKeys.bind(this),
        this.updateCsrfToken.bind(this),
      ],
      transformRequest: [this.decamelizeKeys.bind(this), ...axios.defaults.transformRequest],
    });

    this.instance.interceptors.response.use(this.handleSuccess, this.handleError);
  }

  camelizeKeys(data) {
    return humps.camelizeKeys(data);
  }

  decamelizeKeys(data) {
    return humps.decamelizeKeys(data);
  }

  handleSuccess({ data = {} }) {
    return data;
  }

  handleError({ response = {} }) {
    const { config = {}, data, status } = response;
    const { error, meta } = data || {};
    const message =
      error || (meta && meta.errors ? meta.errors.join(', ') : 'Something went wrong.');
    const isLoginAttempt = config.url === '/api/sessions';

    // If session has expired, just redirect back to login.
    if (!isLoginAttempt && (status === 403 || status === 401)) {
      window.location = Routes.login();
    }

    return Promise.reject(new ApiError(message, status));
  }

  updateCsrfToken(response, headers = {}) {
    if (headers['x-csrf-token']) {
      this.setCSRFToken(headers['x-csrf-token']);
    }

    return response;
  }

  /**
   * API methods.
   */

  async signup(params) {
    return this.post('customers', params);
  }

  /* Do not use this directly. Go through #poll */
  async pollJob(jobId, interval) {
    const response = await this.get(`jobs/${jobId}`);
    const { data } = response;

    if (data && data.id === jobId) {
      await new Promise(resolve => setTimeout(resolve, interval));
      return this.pollJob(jobId, interval);
    }

    return response;
  }

  async poll(request, interval) {
    const { data } = await request();
    const { jobId } = data;

    return this.pollJob(jobId, interval);
  }

  async login(params) {
    return this.post('sessions', params);
  }

  async logout() {
    return this.delete('sessions/sign_out');
  }

  async getUser() {
    return this.get('sessions/current');
  }

  async purchase(params) {
    return this.poll(() => this.post('purchase', params), 2500);
  }

  async purchaseProduct(params) {
    return this.poll(() => this.post('purchase_product', params), 2500);
  }

  async getPackage(params) {
    return this.get('purchase', params);
  }

  async getProductBillingGroup(params) {
    return this.get('purchase_product', params);
  }

  async trackPurchaseView(params) {
    return this.get('purchase/track', params);
  }

  async getVideos() {
    return this.get('videos');
  }

  async getVideo(videoId) {
    return this.get(`videos/${videoId}`);
  }

  async createVideoComment(videoId, params) {
    return this.post(`videos/${videoId}/comments`, params);
  }

  async getGirls(params) {
    return this.get('girls', params);
  }

  async getGirl(girlId) {
    return this.get(`girls/${girlId}`);
  }

  async getGirlGalleries(girlId, params) {
    return this.get(`girls/${girlId}/galleries`, params);
  }

  async updateCurrentUser(params) {
    return this.put('customers/current', params);
  }

  async requestPasswordRecovery(params) {
    return this.post('password_recovery', params);
  }

  async setNewPasswordFromRecovery(params) {
    return this.put('password_recovery', params);
  }

  async getSubscriptions() {
    return this.get('subscriptions');
  }

  async cancelSubscription(id) {
    return this.delete(`subscriptions/${id}`);
  }

  async getDeviceAuthStatus() {
    return this.get('devices/status');
  }

  async authorizeDevice(params) {
    return this.post('devices/authorize', params);
  }

  async getPhotos(params) {
    return this.get('gallery_photos', params);
  }

  /**
   * Request types methods.
   */
  async get(endpoint, params = {}) {
    // GET requests are handled differently within the interceptors. We need
    // to decamelizeKeys "manually" so we'll do it in this method.
    return this.instance.get(endpoint, { params: this.decamelizeKeys(params) });
  }

  async post(endpoint, params = {}) {
    this.ensureCsrf();
    return this.instance.post(endpoint, params);
  }

  async put(endpoint, params = {}) {
    this.ensureCsrf();
    return this.instance.put(endpoint, params);
  }

  async delete(endpoint) {
    this.ensureCsrf();
    return this.instance.delete(endpoint);
  }

  setCSRFToken(value) {
    if (value) {
      this.instance.defaults.headers['X-CSRF-Token'] = value;
    }
  }

  ensureCsrf() {
    if (!this.csrfToken()) {
      this.setCsrfFromHtml();
    }
  }

  csrfToken() {
    return this.instance.defaults.headers['X-CSRF-Token'];
  }

  setCsrfFromHtml() {
    const token = document.querySelector('meta[name="csrf-token"]');
    this.setCSRFToken(token.content);
  }
}
const api = new Api({ baseUrl: '/api' });

export default api;
