import React from 'react';
import SiteConfig from 'site_config';

const Refunds = () => {
  return (
    <div className="extra-refunds container mb-5 mt-4">
      <h2>
        <b>REFUND POLICY</b>
      </h2>
      <p>
        <b>Refunds</b>
      </p>
      <p>
        All sales and transactions are final.
        <b>
          {` Payments are only refundable if there is a technical issue and you are not able to access
          and/or view the videos. If this is the case please email `}
          <a href={`mailto:${SiteConfig.company.supportEmail}`} target="_top">
            {SiteConfig.company.supportEmail}
          </a>
          . There are no refunds or credits for partially used periods.
        </b>
      </p>
      <p>
        <b>Subscription Cancellations</b>
      </p>
      <p>
        {`${SiteConfig.displayName} subscription automatically renews monthly. You may cancel the
        subscription any time before the end of the current billing period and the cancellation
        will take effect on the next billing period. You retain access to the subscription from
        the time you cancel until the start of the next billing period. You can easily cancel your
        subscription on our user dashboard, if needed.`}
      </p>
    </div>
  );
};

export default Refunds;
