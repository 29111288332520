import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppNavLink, RequireUserType } from 'Members/utils';
import { Logo, SocialMediaIcon } from 'Members/components/shared';
import { Routes } from 'Members/const';
import SiteConfig from 'site_config';

const FEATURE_NAVBAR_LINKS = {
  videos: {
    url: Routes.root(),
    isRoute: true,
  },
  photos: {
    url: Routes.photos(),
    isRoute: true,
  },
  girls: {
    url: Routes.girls(),
    isRoute: true,
  },
  cams: {
    url: Routes.cams(),
    isHidden: !SiteConfig.camsEnabled,
  },
};

const NavbarLink = ({ name, url, isRoute, isHidden, isActive, onClick }) => {
  if (isHidden) return null;

  return isRoute ? (
    <AppNavLink className="btn btn-nav" to={url} onClick={onClick} isActive={isActive}>
      {name}
    </AppNavLink>
  ) : (
    <a className="btn btn-nav" href={url} target="_blank" rel="noopener noreferrer">
      {name}
    </a>
  );
};

const Header = ({ dashboardRoutes, photoRoutes, girlsRoutes, settingsRoutes }) => {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  const navClassNames = classNames({ __visible: isMobileNavVisible });

  const onHeaderNavOpenClick = () => setIsMobileNavVisible(true);
  const onHeaderNavCloseClick = () => setIsMobileNavVisible(false);
  const onNavLinkClick = () => setIsMobileNavVisible(false);
  const isOneOfRoutesActive = routes => (match, { pathname }) => routes.indexOf(pathname) !== -1;
  const isNavbarLinkActive = {
    videos: isOneOfRoutesActive(dashboardRoutes),
    photos: isOneOfRoutesActive(photoRoutes),
    girls: isOneOfRoutesActive(girlsRoutes),
    settings: isOneOfRoutesActive(settingsRoutes),
  };

  return (
    <header className="ma-container d-flex flex-row align-items-center justify-content-between flex-nowrap py-3 px-4">
      <div className="header-logo">
        <AppNavLink to={Routes.root()}>
          <Logo />
        </AppNavLink>
      </div>

      <nav className={navClassNames}>
        <div className="header-nav-close" onClick={onHeaderNavCloseClick}>
          <FontAwesomeIcon icon={['fal', 'times']} size="2x" />
        </div>

        {SiteConfig.navbar.map(link => (
          <NavbarLink
            key={link.id}
            {...FEATURE_NAVBAR_LINKS[link.id]}
            {...link}
            onClick={onNavLinkClick}
            isActive={isNavbarLinkActive[link.id]}
          />
        ))}

        <RequireUserType authenticated>
          <AppNavLink
            to={Routes.accountSettings()}
            className="btn btn-nav"
            onClick={onNavLinkClick}
            isActive={isNavbarLinkActive.settings}
          >
            Account
          </AppNavLink>
        </RequireUserType>

        <RequireUserType guest>
          <AppNavLink to={Routes.login()} className="btn btn-nav" noscroll>
            Log In
          </AppNavLink>
        </RequireUserType>

        <div className="header-nav-social">
          <SocialMediaIcon name="instagram" url={SiteConfig.social.instagram} />
          <SocialMediaIcon name="twitter" url={SiteConfig.social.twitter} />
          <SocialMediaIcon name="facebook" url={SiteConfig.social.facebook} />
        </div>
      </nav>

      <div className="header-nav-open" onClick={onHeaderNavOpenClick}>
        <FontAwesomeIcon icon={['fal', 'bars']} size="2x" />
      </div>
    </header>
  );
};

NavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isRoute: PropTypes.bool,
  isHidden: PropTypes.bool,
  isActive: PropTypes.func,
  onClick: PropTypes.func.isRequired,
};

NavbarLink.defaultProps = {
  isRoute: false,
  isHidden: false,
  isActive: () => false,
};

Header.propTypes = {
  dashboardRoutes: PropTypes.arrayOf(PropTypes.string),
  photoRoutes: PropTypes.arrayOf(PropTypes.string),
  girlsRoutes: PropTypes.arrayOf(PropTypes.string),
  settingsRoutes: PropTypes.arrayOf(PropTypes.string),
};

Header.defaultProps = {
  dashboardRoutes: [],
  photoRoutes: [],
  girlRoutes: [],
  settingsRoutes: [],
};

export default Header;
