import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { Logo, LazyImage } from 'Members/components/shared';
import { RequireUserType, RequireProductAccess } from 'Members/utils';
import { Routes, Messages } from 'Members/const';

const CtaJumbotron = ({
  className,
  ctaClassName,
  onCtaButtonClick,
  onLoginButtonClick,
  children,
}) => {
  const containerCss = classNames('girl-details-jumbotron', className);
  const ctaCss = classNames('girl-details-jumbotron-cta', ctaClassName);

  return (
    <div className={containerCss}>
      {children}

      <div className={ctaCss}>
        <h5>{Messages.girlPurchaseCta}</h5>
        <RequireUserType guest>
          <br />
          <button className="btn btn-upgrade border-light" onClick={onLoginButtonClick}>
            Login
          </button>
        </RequireUserType>
      </div>
    </div>
  );
};

const GirlDetails = ({
  id,
  slug,
  productId,
  name,
  headerUrl,
  onCtaButtonClick,
  onLoginButtonClick,
}) => (
  <section className="girl-details-section container-fluid px-0">
    {/* Redirect them to the actual girl's page if they have VU subscription */}
    <RequireUserType premium>
      <Redirect to={Routes.girl({ id, slug })} />
    </RequireUserType>

    {/* Redirect them to the actual girl's page if they have access to the girl's product */}
    <RequireProductAccess productId={productId} full>
      <Redirect to={Routes.girl({ id, slug })} />
    </RequireProductAccess>

    <RequireProductAccess productId={productId} free>
      <div className="girl-details-hero text-center">
        <LazyImage src={headerUrl} />
        <Logo className="girl-details-vu-logo d-none d-lg-block" />

        <CtaJumbotron
          ctaClassName="w-50 m-auto d-none d-md-block"
          onCtaButtonClick={onCtaButtonClick}
          onLoginButtonClick={onLoginButtonClick}
        >
          <h1 className="d-none d-md-block">{name}</h1>
        </CtaJumbotron>
      </div>
      <div className="row girl-details-description text-center px-2 mx-auto bg-section">
        <CtaJumbotron
          className="col-12 my-3 d-md-none"
          onCtaButtonClick={onCtaButtonClick}
          onLoginButtonClick={onLoginButtonClick}
        >
          <h1 className="d-md-none">{name}</h1>
        </CtaJumbotron>
      </div>
    </RequireProductAccess>
  </section>
);

CtaJumbotron.propTypes = {
  className: PropTypes.string,
  ctaClassName: PropTypes.string,
  onCtaButtonClick: PropTypes.func.isRequired,
  onLoginButtonClick: PropTypes.func.isRequired,
};

GirlDetails.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  productId: PropTypes.number,
  name: PropTypes.string.isRequired,
  headerUrl: PropTypes.string.isRequired,
  onCtaButtonClick: PropTypes.func.isRequired,
  onLoginButtonClick: PropTypes.func.isRequired,
};

export default GirlDetails;
