/**
 * This is a HOC to provide global app state for other components in a tree.
 * It's Redux-like and it uses reducers and actions too.
 *
 * The only way to change the global app state is to dispatch actions.
 * Actions are very simple, they are plain object with `type` and `data` fields.
 * E.g. { type: 'AUTH_LOGIN', data: { username: 'mrskin', password: 'sideboobs' }
 *
 * When an action is dispatched, it's passed to a reducer. Reducer is a pure
 * function which provides new value of a state out of a current state and an action.
 *
 * Reducer can be combined out of multiple smaller reducers which only change
 * a portion of a global app state.
 *
 * You can access a global app state using a special hook `useAppState`.
 *
 * E.g.:
 * import { useAppState } = 'Members/hooks';
 *
 * const Counter = () => {
 *   const [state, dispatch] = useAppState();
 *   const onClick = () => dispatch({ type: 'INC', data: 1 });
 *
 *   return <div onClick={onClick}>{state.counter}</div>;
 * };
 *
 * See more info on how it works here:
 * https://link.medium.com/3Pg9SiZGRW
 */

import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const StateContext = createContext();

export const StateProvider = ({ initialState, reducer, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

StateProvider.propTypes = {
  initialState: PropTypes.object.isRequired,
  reducer: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
