import { REQUEST_SUCCESS, REQUEST_ERROR, HIDE_ALERT } from 'Members/actions';

export default (alert, action) => {
  switch (action.type) {
    case REQUEST_SUCCESS:
      return { type: 'success', message: action.data };
    case REQUEST_ERROR:
      return { type: 'danger', message: action.data };
    case HIDE_ALERT:
      return undefined;
    default:
      return alert;
  }
};
