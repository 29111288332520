/**
 * This utility component allows to scroll page to top
 * on React router navigation.
 *
 * E.g.:
 * <ScrollToTop>
 *   <Route path="/route" exact component={WillScrollToTop} />
 * </ScrollToTop>
 */

import { useEffect } from 'react';
import { withRouter } from 'react-router';

const ScrollToTop = ({ children, history: { action }, location: { pathname, state } }) => {
  const { noscroll } = state || {};
  const isBack = action === 'POP';

  useEffect(() => {
    if (!noscroll && !isBack) window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default withRouter(ScrollToTop);
