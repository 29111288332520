import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Routes } from 'Members/const';
import { useAppState, useForm } from 'Members/hooks';
import { useHistory } from 'react-router-dom';

import {
  clickLogoutLink,
  getJoinFormData,
  submitPaymentForm,
  trackPurchaseView,
} from 'Members/actions';

import InlineAlert from 'Members/components/InlineAlert';
import { validatePaymentForm } from './Form';
/*
  If we have an active a/b test, activate this
*/
const EXPECTING_SPLIT_TEST = false;

/** ************************ */
/* Render the payment form */
/** ************************ */
const Payment = ({
  history,
  subTitle,
  freeProductId,
  getBillingInfoAction,
  trackViewAction,
  submitAction,
}) => {
  const [{ user, errors: formSubmissionErrors, splitTest = {} }, dispatch] = useAppState();
  const { name: splitTestName } = splitTest; /* use experiment versions */

  const [joinFormData, setJoinFormData] = useState({});

  const { values, edits, errors, setValues, isSubmitting, isValid, onChange, onSubmit } = useForm(
    submitAction.bind(this, dispatch),
    validatePaymentForm
  );

  /* Send XHR Request to get billing package information and build the form */
  useEffect(() => {
    getBillingInfoAction({}, setJoinFormData.bind(this));
  }, []);

  return <h1></h1>;
};

Payment.defaultProps = {
  getBillingInfoAction: getJoinFormData,
  trackViewAction: trackPurchaseView,
  submitAction: submitPaymentForm,
};

Payment.propTypes = {
  history: PropTypes.object,
  subTitle: PropTypes.string,
  freeProductId: PropTypes.number,
  getBillingInfoAction: PropTypes.func,
  trackViewAction: PropTypes.func,
  submitAction: PropTypes.func,
};

export default Payment;
