import React from 'react';
import ReactDOM from 'react-dom';
import { useAppState } from 'Members/hooks';
import { hideConfirmDialog } from 'Members/actions';

const ConfirmDialog = () => {
  const [{ confirm = null }, dispatch] = useAppState();
  const isVisible = !!confirm;
  const { title, text, onSubmit } = confirm || {};

  const onCancel = () => {
    onSubmit(false);
    hideConfirmDialog(dispatch);
  };

  const onContinue = () => {
    onSubmit(true);
    hideConfirmDialog(dispatch);
  };

  return isVisible
    ? ReactDOM.createPortal(
        <div className="confirm-dialog-container">
          <div className="confirm-dialog-overlay" onClick={onCancel} />
          <div className="confirm-dialog-body">
            <h1>{title}</h1>
            <p>{text}</p>
            <button className="btn btn-accent mx-1 py-1 px-2" onClick={onContinue}>
              OK
            </button>
            <button className="btn btn-ghost mx-1 py-1 px-2" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>,
        document.body
      )
    : null;
};

export default ConfirmDialog;
