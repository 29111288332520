/* eslint-disable react/no-unknown-property */

import React from 'react';
import PropTypes from 'prop-types';
import { Routes } from 'Members/const';
import { camsEnabled } from 'site_config';

const CamsIframe = ({ url }) => {
  if (!camsEnabled) {
    return null;
  }

  return (
    <div className="d-flex flex-column align-items-center p-4 mx-0 mb-4 bg-base text-base-contrast">
      <iframe
        allowtransparency="true"
        width="100%"
        height="250"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        scrolling="no"
        src={url}
      ></iframe>

      <a
        href={Routes.cams()}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-outline-light rounded-pill btn-md mt-5 px-3 text-uppercase"
      >
        See All Live Girls &#x3E;&#x3E;
      </a>
    </div>
  );
};

CamsIframe.propTypes = {
  url: PropTypes.string.isRequired,
};

export default CamsIframe;
