import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AppLink } from 'Members/utils';
import { useAppState, useForm } from 'Members/hooks';
import { submitLoginForm } from 'Members/actions';
import { Routes } from 'Members/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecaptchaNotice } from 'Members/components/shared';
import InlineAlert from 'Members/components/InlineAlert';
import { Input, validateLoginForm } from './Form';

const Login = ({ title, supportRoute, forgotPasswordRoute, className }) => {
  const [, dispatch] = useAppState();

  const { values, edits, errors, formAlert, isValid, isSubmitting, onChange, onSubmit } = useForm(
    submitLoginForm.bind(this, dispatch),
    validateLoginForm
  );

  const formCss = classNames('auth-login-form auth-form', className);
  const loginBtnCss = classNames({
    'auth-form-submit': true,
    btn: true,
    'btn-accent': true,
    'btn-lg': true,
    disabled: isSubmitting || !isValid,
  });

  return (
    <form className={formCss} onSubmit={onSubmit} noValidate>
      <h1>{title}</h1>

      {formAlert && <InlineAlert icon={{ icon: ['fal', 'exclamation-triangle'] }} {...formAlert} />}

      <fieldset>
        <Input
          type="email"
          name="login"
          icon={['fal', 'envelope']}
          placeholder="Email"
          autocomplete="email"
          value={values.login || ''}
          error={edits.login && errors.login}
          onChange={onChange}
          disabled={isSubmitting}
        />

        <Input
          type="password"
          name="password"
          icon={['fal', 'lock-alt']}
          placeholder="Password"
          autocomplete="current-password"
          value={values.password || ''}
          error={edits.password && errors.password}
          onChange={onChange}
          disabled={isSubmitting}
        />
      </fieldset>

      <button type="submit" className={loginBtnCss} disabled={isSubmitting || !isValid}>
        {isSubmitting && <FontAwesomeIcon icon={['fal', 'spinner-third']} spin />}
        {!isSubmitting && 'Login'}
      </button>

      {supportRoute && (
        <a
          href={supportRoute}
          className="auth-form-alt-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Customer Support
        </a>
      )}

      <AppLink to={forgotPasswordRoute} className="auth-form-alt-link" noscroll>
        Forgot password?
      </AppLink>

      <RecaptchaNotice />
    </form>
  );
};

Login.defaultProps = {
  title: 'Login',
  forgotPasswordRoute: Routes.forgotPassword(),
};

Login.propTypes = {
  title: PropTypes.string,
  supportRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  forgotPasswordRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default Login;
