import ReactGA from 'react-ga';
import { amountInCents } from 'Members/utils/purchase_helper';
import { IsDebug, GATrackingId, GOContainerId } from 'Members/const';

class Tracker {
  constructor({ trackingId, testingId }) {
    this.trackingId = trackingId;
    this.testingId = testingId;
  }

  initialize(options = {}) {
    ReactGA.initialize(this.trackingId, {
      debug: IsDebug,
      ...options,
    });

    ReactGA.plugin.require('ecommerce');
    ReactGA.plugin.require(this.testingId);
  }

  set(config) {
    ReactGA.set(config);
  }

  setUserId(userId) {
    ReactGA.set({ userId });
  }

  setUserExperiment({ experimentId, variationId }) {
    const id = `${experimentId}.${variationId}`;

    this.set({ exp: id });

    ReactGA.event({
      category: 'UX Experiments',
      action: 'Started',
      label: id.toString(),
    });
  }

  pageview(url) {
    if (/\/forgot-password\/.+/.test(url)) {
      ReactGA.pageview('/forgot-password/reset');
    } else {
      ReactGA.pageview(url);
    }
  }

  purchaseSubmitAttempt({ crossSaleId, crossSaleName }) {
    this.triggerCrossSaleEvent({
      eventName: 'Purchase form cross-sale submission attempt',
      crossSaleId,
      crossSaleName,
    });
  }

  triggerCrossSaleEvent({ crossSaleId, crossSaleName, eventName }) {
    const label = crossSaleName;
    const value = crossSaleId && crossSaleId !== 'false' ? 1 : 0;

    if (crossSaleName) {
      ReactGA.event({
        category: 'User',
        action: eventName,
        value,
        label,
      });
    }
  }

  purchase({ crossSaleId, crossSaleName, transactionId, packageId, packageAmount, packageName }) {
    const label = packageId.toString();
    const value = amountInCents(packageAmount);

    ReactGA.event({
      category: 'User',
      action: 'Purchased a Subscription',
      label,
      value,
    });

    this.triggerCrossSaleEvent({
      eventName: 'Purchase form cross-sale submission successful',
      crossSaleId,
      crossSaleName,
    });

    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id: transactionId,
      revenue: packageAmount,
    });

    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id: transactionId,
      name: packageName,
      price: packageAmount,
      quantity: 1,
    });

    ReactGA.plugin.execute('ecommerce', 'send');
  }

  videoPlay(id) {
    ReactGA.event({
      category: 'Video',
      action: 'Video Play',
      label: id.toString(),
    });
  }

  videoSeeked(id, percent) {
    ReactGA.event({
      category: 'Video',
      action: 'Video Seeked',
      label: id.toString(),
      value: Math.round(percent * 100),
    });
  }

  videoEnded(id) {
    ReactGA.event({
      category: 'Video',
      action: 'Video Ended',
      label: id.toString(),
    });
  }

  deviceAuthorized(userId, isAuthorized) {
    ReactGA.event({
      category: 'User',
      action: 'Device Authorized',
      label: userId.toString(),
      value: isAuthorized ? 1 : 0,
    });
  }
}

const tracker = new Tracker({
  trackingId: GATrackingId,
  testingId: GOContainerId,
});

export default tracker;
