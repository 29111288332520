import React from 'react';
import PropTypes from 'prop-types';
import userAvatar from 'images/user.png';
import { useAppState, useForm } from 'Members/hooks';
import { submitVideoComment } from 'Members/actions';
import { validateReplyForm } from './validations';

const ReplyForm = ({ videoId, commentId, rootIndex }) => {
  const [, dispatch] = useAppState();
  const { values, isValid, onChange, onSubmit } = useForm(
    submitVideoComment.bind(this, dispatch),
    validateReplyForm,
    { videoId, commentId, rootIndex }
  );

  return (
    <div className="reply-form-container">
      <div className="reply-form-avatar">
        <img className="img-fluid" src={userAvatar} />
      </div>

      <form className="reply-form" onSubmit={onSubmit} noValidate>
        <textarea
          name="body"
          className="form-control text-section-contrast"
          placeholder="Write something..."
          value={values.body || ''}
          onChange={onChange}
          rows="4"
        />

        <div className="reply-form-submit">
          <button
            type="submit"
            className="btn btn-regular btn-sm text-section-contrast border-section-contrast"
            disabled={!isValid}
          >
            Post Comment
          </button>
        </div>
      </form>
    </div>
  );
};

ReplyForm.propTypes = {
  videoId: PropTypes.number.isRequired,
  commentId: PropTypes.number,
  rootIndex: PropTypes.number,
};

ReplyForm.defaultProps = {
  commentId: null,
  rootIndex: null,
};

export default ReplyForm;
