import React, { useEffect } from 'react';
import { useAppState } from 'Members/hooks';
import SiteConfig from 'site_config';

let scriptLoaded = false;

const ScriptTag = ({ user }) => {
  if (scriptLoaded) {
    return null;
  }

  scriptLoaded = true;

  const onReady = () => {
    window.fcWidget.init({
      token: '3eb91f49-f7c0-4aa5-a5ce-c7ca8b4e1579',
      host: 'https://wchat.freshchat.com',
      siteId: SiteConfig.displayName,
    });
    if (!user) {
      window.fcWidget.hide();
    }
  };

  const initialize = id => {
    if (document.getElementById(id)) {
      onReady();
    } else {
      const script = document.createElement('script');
      script.id = id;
      script.async = true;
      script.type = 'text/javascript';
      script.src = 'https://wchat.freshchat.com/js/widget.js';
      script.onreadystatechange = onReady;
      script.onload = onReady;

      document
        .getElementsByTagName('HEAD')
        .item(0)
        .appendChild(script);
    }
  };

  if (window.addEventListener) {
    window.addEventListener('load', initialize('Freshdesk Messaging-js-sdk'), false);
  } else {
    window.attachEvent('load', initialize('Freshdesk Messaging-js-sdk'), false);
  }

  return null;
};

const FreshChatWidget = () => {
  const [{ user }] = useAppState();

  useEffect(() => {
    if (window.fcWidget) {
      if (user) {
        window.fcWidget.show();
      } else {
        window.fcWidget.hide();
      }
    }
  }, [user]);

  return <ScriptTag user={user} />;
};

FreshChatWidget.propTypes = {};

export default FreshChatWidget;
