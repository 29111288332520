import React from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import { Routes } from 'Members/const';
import SiteConfig from 'site_config';

const TITLES = {
  [Routes.photos()]: 'Photos',
  [Routes.girls()]: 'Girls',
  [Routes.accountSettings()]: 'Account',
  [Routes.accountBilling()]: 'Billing',
  [Routes.deviceAuth()]: 'Device Authorization',
  [Routes.copyrightAgent()]: 'Copyright Agent',
  [Routes.terms()]: 'Terms of Service',
  [Routes.privacy()]: 'Privacy Policy',
  [Routes.compliance()]: '2257 Compliance Statement',
  [Routes.refunds()]: 'Refund Policy',
  [Routes.tour()]: 'Tour',
  [Routes.tourLogin()]: 'Tour Login',
  [Routes.tourSignup()]: 'Tour Signup',
  [Routes.tourSignupPayment()]: 'Tour Purchase',
  [Routes.tourForgotPassword()]: 'Tour Password Reset',
  [Routes.tourForgotPasswordWithToken()]: 'Tour New Password',
};

const DefaultHelmet = () => {
  const { pathname } = useLocation();
  const title = TITLES[pathname]
    ? `${TITLES[pathname]} - ${SiteConfig.displayName}`
    : SiteConfig.displayName;

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default DefaultHelmet;
