import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAppState, handleAuthRouteModal } from 'Members/hooks';
import { showModal, hideModal, hideGalleryViewer } from 'Members/actions';

const onModalLinkClick = (dispatch, modal) => {
  if (modal) {
    return event => {
      event.preventDefault();
      showModal(dispatch, modal);
    };
  }

  return () => {
    hideModal(dispatch);
    hideGalleryViewer(dispatch);
  };
};

const extendLinkState = (to, state) => {
  switch (typeof to) {
    case 'string':
      return { pathname: to, state };
    case 'object':
      return { ...to, state: { ...to.state, ...state } };
    default:
      return to;
  }
};

const decorateLink = Component =>
  function DecoratedLink({ to, noscroll, scrollTo, ...rest }) {
    // If this is an auth link, override the onClick handler and use
    // showModal action to show the corresponding modal.
    const [, dispatch] = useAppState();
    const { modal } = handleAuthRouteModal({ route: to });
    const onClick = onModalLinkClick(dispatch, modal);
    const state = { noscroll, scrollTo };

    return <Component to={extendLinkState(to, state)} onClick={onClick} {...rest} />;
  };

export const AppLink = decorateLink(Link);
export const AppNavLink = decorateLink(NavLink);
