import { ModalName } from 'Members/const';
import { MODAL_SHOW, MODAL_HIDE } from './types';

export const hideModal = dispatch => {
  dispatch({ type: MODAL_HIDE });
};

export const showModal = (dispatch, modal) => {
  if (!modal) {
    hideModal(dispatch);
  } else {
    dispatch({ type: MODAL_SHOW, data: modal });
  }
};

export const showAccessModal = dispatch => showModal(dispatch, ModalName.access);
export const showLoginModal = dispatch => showModal(dispatch, ModalName.login);
export const showSignupModal = dispatch => showModal(dispatch, ModalName.signup);
export const showPaymentModal = dispatch => showModal(dispatch, ModalName.payment);
export const showForgotPasswordModal = dispatch => showModal(dispatch, ModalName.forgotPassword);
export const showResetPasswordModal = dispatch => showModal(dispatch, ModalName.resetPassword);
