import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyImage } from 'Members/components/shared';
import { AppLink } from 'Members/utils';
import { Routes } from 'Members/const';

const GirlHeaderThumbnail = ({ id, slug, name, headerUrl, className }) => {
  const thumbnailCss = classNames('girl-header-thumb', className);

  return (
    <AppLink to={Routes.girl({ id, slug })} className={thumbnailCss}>
      <div className="girl-header-thumb-image">
        <LazyImage src={headerUrl} observe />
      </div>

      <div className="girl-header-thumb-caption">
        <div className="girl-header-thumb-name">{name}</div>
      </div>
    </AppLink>
  );
};

GirlHeaderThumbnail.defaultProps = {
  className: '',
};

GirlHeaderThumbnail.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string,
  name: PropTypes.string.isRequired,
  headerUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default GirlHeaderThumbnail;
