import { CONFIRM_SHOW, CONFIRM_HIDE } from 'Members/actions';

export default (confirm, action) => {
  switch (action.type) {
    case CONFIRM_SHOW:
      return action.data;
    case CONFIRM_HIDE:
      return null;
    default:
      return confirm;
  }
};
