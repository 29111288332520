import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'Members/hooks';
import { RequireUserType } from 'Members/utils';
import { fetchPhotos, showAccessModal } from 'Members/actions';

import {
  InfiniteContent,
  GalleriesGrid,
  GalleryViewer,
  GirlTagLinks,
} from 'Members/components/shared';

const GalleryViewerCaption = ({ description, girls }) => {
  return (
    <Fragment>
      <p>{description}</p>
      <GirlTagLinks girls={girls} className="mt-2" />
    </Fragment>
  );
};

const Photos = () => {
  const [, dispatch] = useAppState();
  const onUpgradeButtonClick = () => showAccessModal(dispatch);

  return (
    <main className="photos-page ma-container text-center px-3 py-5 bg-section text-section-contrast">
      <h1 className="section-title mb-5">Photos</h1>
      <InfiniteContent fetch={fetchPhotos}>
        {({ content }) => (
          <GalleriesGrid
            key={content.length}
            galleries={content}
            thumbnailClassName="col-4"
            captionClassName="d-none"
          />
        )}
      </InfiniteContent>
      <RequireUserType guest freemium>
        <button
          className="btn btn-upgrade mt-5 text-section-contrast"
          onClick={onUpgradeButtonClick}
        >
          Upgrade To Unlock All Photos &gt;&gt;
        </button>
      </RequireUserType>
      <GalleryViewer caption={GalleryViewerCaption} />
    </main>
  );
};

GalleryViewerCaption.propTypes = {
  description: PropTypes.string,
  girls: PropTypes.arrayOf(PropTypes.object),
};

export default Photos;
