import React from 'react';
import { AppLink } from 'Members/utils';
import { useAppState, useForm } from 'Members/hooks';
import { submitSignupForm } from 'Members/actions';
import { Routes } from 'Members/const';
import { Input, validateSignupForm } from './Form';

const InlineSignup = () => {
  const [, dispatch] = useAppState();

  const { values, edits, errors, isValid, onChange, onSubmit } = useForm(
    submitSignupForm.bind(this, dispatch),
    validateSignupForm,
    { emailable: true }
  );

  return <h1></h1>;
};

export default InlineSignup;
