/**
 * A generic hook for forms. It allows to:
 *
 * - watch for form input changes and store actual state of form data;
 * - validate form input changes on the fly;
 * - watch for form's state (submission and validation);
 * - provide a callback for successful submission.
 *
 * See auth forms implementation for example of usage.
 */

import { useState, useEffect } from 'react';

const useForm = (callback, validate, defaultValues = {}) => {
  const [values, setValues] = useState(defaultValues);
  const [edits, setEdits] = useState({});
  const [errors, setErrors] = useState(validate(values));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const isValid = Object.keys(errors).length === 0;

  useEffect(() => {
    const abortController = new AbortController();

    if (isValid && isSubmitting) {
      (async () => {
        const msg = await callback(values);

        if (abortController.signal.aborted) {
          return;
        }

        if (msg) {
          setFormAlert(msg);
        }
        setIsSubmitting(false);
      })();
    }

    return () => {
      abortController.abort();
    };
  }, [errors]);

  useEffect(() => {
    const abortController = new AbortController();

    if (!abortController.signal.aborted) {
      setErrors(validate(values));
    }

    return () => {
      abortController.abort();
    };
  }, [values]);

  const onSubmit = event => {
    if (event) event.preventDefault();

    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const onChange = event => {
    const { name, value } = event.target;

    if (typeof event.persist === 'function') {
      event.persist();
    }

    setValues(oldValues => ({ ...oldValues, [name]: value }));
    setEdits(oldEdits => ({ ...oldEdits, [name]: true }));
  };

  return {
    values,
    edits,
    errors,
    formAlert,
    isSubmitting,
    isValid,
    onSubmit,
    onChange,
    setValues,
    setEdits,
    setFormAlert,
  };
};

export default useForm;
