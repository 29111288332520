import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ICONS = {
  instagram: ['fab', 'instagram'],
  twitter: ['fab', 'twitter'],
  facebook: ['fab', 'facebook-square'],
  website: ['fas', 'external-link-square-alt'],
};

const SocialMediaIcon = ({ name, url }) => {
  return url ? (
    <a className="btn btn-sm" href={url} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={ICONS[name]} size="2x" />
    </a>
  ) : null;
};

SocialMediaIcon.propTypes = {
  name: PropTypes.oneOf(['instagram', 'twitter', 'facebook', 'website']).isRequired,
  url: PropTypes.string,
};

export default SocialMediaIcon;
