import React from 'react';
import { useAppState, useForm } from 'Members/hooks';
import { submitDeviceAuthForm } from 'Members/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InlineAlert from 'Members/components/InlineAlert';
import classNames from 'classnames';
import { Input, validateDeviceAuthForm } from '../../Auth/Form';

const Form = () => {
  const [, dispatch] = useAppState();

  const { values, edits, errors, formAlert, isValid, isSubmitting, onChange, onSubmit } = useForm(
    submitDeviceAuthForm.bind(this, dispatch),
    validateDeviceAuthForm
  );

  const authBtnCss = classNames({
    'auth-form-submit': true,
    btn: true,
    'btn-accent': true,
    'btn-lg': true,
    disabled: isSubmitting || !isValid,
  });

  return (
    <form className="device-auth-form auth-form" onSubmit={onSubmit} noValidate>
      <h2>Device Authorization Required</h2>

      <p>
        In order to maintain the integrity of your account, access to view or update this
        information is restricted to authorized devices only. Please enter your account password to
        authorize your device.
      </p>

      {formAlert && <InlineAlert icon={{ icon: ['fal', 'exclamation-triangle'] }} {...formAlert} />}

      <fieldset>
        <Input
          type="password"
          name="password"
          icon={['fal', 'lock-alt']}
          placeholder="Password"
          autocomplete="current-password"
          value={values.password || ''}
          error={edits.password && errors.password}
          onChange={onChange}
          disabled={isSubmitting}
        />
      </fieldset>

      <button type="submit" className={authBtnCss} disabled={isSubmitting || !isValid}>
        {isSubmitting && <FontAwesomeIcon icon={['fal', 'spinner-third']} spin />}
        {!isSubmitting && 'Authorize'}
      </button>
    </form>
  );
};

export default Form;
