import { api } from 'Members/utils';

export const fetchPhotos = async (dispatch, page = 0) => {
  try {
    const { data, meta } = await api.getPhotos({ page });
    dispatch({ data, meta });
  } catch ({ message }) {
    dispatch({ error: message });
  }
};
