import { GIRL_FETCHED } from 'Members/actions';

export default (girl, action) => {
  switch (action.type) {
    case GIRL_FETCHED:
      return action.data;
    default:
      return girl;
  }
};
