import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { LoadingProgress } from 'Members/components/shared';

const INTERSECTION_OBSERVER_SUPPORTED = 'IntersectionObserver' in window;
const INTERSECTION_OBSERVER_OPTIONS = {
  rootMargin: '15%',
  threshold: 0,
};

const LazyImage = ({ src, spinner, observe, className, ...rest }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null);
  const imageClassNames = classNames(className, 'lazy');
  const onImageLoad = () => setIsLoaded(true);
  const shouldObserve = INTERSECTION_OBSERVER_SUPPORTED && observe;

  const setupImageLoading = () => {
    setIsLoaded(false);

    imageRef.current.src = src;

    if (imageRef.current.complete) {
      onImageLoad();
    } else {
      imageRef.current.addEventListener('load', onImageLoad);
    }
  };

  useEffect(() => {
    if (shouldObserve) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setupImageLoading();
            observer.unobserve(imageRef.current);
          }
        });
      }, INTERSECTION_OBSERVER_OPTIONS);

      observer.observe(imageRef.current);
    } else {
      setupImageLoading();
    }

    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', onImageLoad);
        imageRef.current.src = '';
      }
    };
  }, [src]);

  return (
    <CSSTransition in={isLoaded} classNames="lazy" timeout={300}>
      <Fragment>
        <img ref={imageRef} className={imageClassNames} {...rest} />
        {spinner && !isLoaded && <LoadingProgress />}
      </Fragment>
    </CSSTransition>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  spinner: PropTypes.bool,
  observe: PropTypes.bool,
};

export default LazyImage;
