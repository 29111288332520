import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Input = ({
  type,
  name,
  className,
  icon,
  placeholder,
  autocomplete,
  value,
  error,
  onChange,
  disabled,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const inputGroupClassNames = classNames('input-lg input-group flex-wrap', className, {
    __icon: !!icon,
    __selected: isSelected,
    __invalid: !!error,
  });

  const onFocus = () => setIsSelected(true);
  const onBlur = () => setIsSelected(false);

  return (
    <div className={inputGroupClassNames}>
      {icon && (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <FontAwesomeIcon icon={icon} />
          </span>
        </div>
      )}

      <input
        type={type}
        name={name}
        className="form-control form-control-lg"
        value={value}
        placeholder={placeholder}
        autoComplete={autocomplete}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />

      {error && <span className="text-danger w-100">{error}</span>}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
