import { UPDATE_SPLIT_TEST } from 'Members/actions';
import { SPLIT_TESTS } from 'Members/const';

const findName = experimentId => {
  const keys = Object.keys(SPLIT_TESTS);

  const key = keys.find(name => {
    return SPLIT_TESTS[name].experimentId === experimentId;
  });

  try {
    return SPLIT_TESTS[key].name;
  } catch (e) {
    return null;
  }
};

const splitTestName = ({ experimentId, variationId }) => {
  if (experimentId && variationId >= 0) {
    return { name: `${findName(experimentId)}_v${variationId}` };
  }
  return {};
};

export default (original, action) => {
  switch (action.type) {
    case UPDATE_SPLIT_TEST:
      return { ...action.data, ...splitTestName(action.data) };
    default:
      return original;
  }
};
