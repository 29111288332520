import React from 'react';
import PropTypes from 'prop-types';
import { fetchGirlGalleries } from 'Members/actions';
import { RequireUserType, RequireProductAccess } from 'Members/utils';
import { InfiniteContent, GalleriesGrid } from 'Members/components/shared';
import GirlVideosCarousel from './girl_videos_carousel';
import GirlPhotosCarousel from './girl_photos_carousel';

const GirlContent = ({ id, productId, firstName, videos, photos, onCtaButtonClick }) => {
  const fetchGalleries = (onContentFetched, nextPage) =>
    fetchGirlGalleries(onContentFetched, id, nextPage);

  return (
    <section className="girl-content-section container-fluid px-3 py-5 bg-section text-section-contrast">
      <GirlVideosCarousel videos={videos} title={`Episodes with ${firstName}`} />
      <GirlPhotosCarousel photos={photos} title={`Photos with ${firstName}`} />

      <h1 className="section-title mb-5">{`${firstName}'s Private\xa0Updates`}</h1>
      <br />

      <InfiniteContent fetch={fetchGalleries}>
        {({ content }) => (
          <GalleriesGrid
            key={content.length}
            galleries={content}
            thumbnailClassName="col-12 col-sm-4 mb-3 mb-sm-0"
            captionClassName="bg-base p-2 d-sm-none"
          />
        )}
      </InfiniteContent>

      <RequireProductAccess productId={productId} free>
        <button
          className="btn btn-upgrade mt-5 mx-auto d-block text-section-contrast"
          onClick={onCtaButtonClick}
        >
          <RequireUserType premium>Click to Unlock My Updates &gt;&gt;</RequireUserType>
          <RequireUserType guest freemium>
            Join to Unlock My Updates &gt;&gt;
          </RequireUserType>
        </button>
      </RequireProductAccess>
    </section>
  );
};

GirlContent.propTypes = {
  id: PropTypes.number.isRequired,
  productId: PropTypes.number,
  firstName: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(PropTypes.object),
  photos: PropTypes.arrayOf(PropTypes.object),
  onCtaButtonClick: PropTypes.func.isRequired,
};

export default GirlContent;
