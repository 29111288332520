import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  Login,
  Signup,
  Payment,
  ResetPassword,
  ForgotPassword,
  GirlSignup,
  GirlPayment,
  GirlOneClick,
} from 'Members/components/Auth';
import { hideModal } from 'Members/actions';
import { isGuest, isFreemium, hasCreditCard } from 'Members/utils';
import { useAppState } from 'Members/hooks';
import { Routes } from 'Members/const';
import AuthModalBase from './auth_modal_base';

export const NoModal = () => {
  const [, dispatch] = useAppState();

  hideModal(dispatch);

  return null;
};

export const LoginModal = () => (
  <AuthModalBase>
    <Login />
  </AuthModalBase>
);

export const SignupModal = () => (
  <AuthModalBase>
    <Switch>
      <Route path={Routes.girl()} exact component={GirlSignup} />
      <Route path={Routes.girlTour()} exact component={GirlSignup} />
      <Route component={Signup} />
    </Switch>
  </AuthModalBase>
);

export const PaymentModal = () => (
  <AuthModalBase>
    <Switch>
      <Route path={Routes.girl()} exact component={GirlPayment} />
      <Route path={Routes.girlTour()} exact component={GirlPayment} />
      <Route component={Payment} />
    </Switch>
  </AuthModalBase>
);

export const ResetPasswordModal = () => (
  <AuthModalBase>
    <ResetPassword />
  </AuthModalBase>
);

export const ForgotPasswordModal = () => (
  <AuthModalBase>
    <ForgotPassword />
  </AuthModalBase>
);

export const OneClickModal = () => (
  <AuthModalBase>
    <Switch>
      <Route path={Routes.girl()} exact component={GirlOneClick} />
      <Route component={NoModal} />
    </Switch>
  </AuthModalBase>
);

export const AccessModal = () => {
  const [{ user }] = useAppState();

  if (isGuest(user)) return <SignupModal />;
  if (isFreemium(user)) return <PaymentModal />;
  if (hasCreditCard(user)) return <OneClickModal />;

  return null;
};
