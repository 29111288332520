import React from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'Members/hooks';
import { showAccessModal, showGalleryViewer } from 'Members/actions';
import { GalleryThumbnail, ThumbnailsCarousel } from 'Members/components/shared';

const GirlPhotosCarousel = ({ photos, title }) => {
  const [, dispatch] = useAppState();
  const hasPhotos = photos && photos.length > 0;

  const onThumbnailClick = (photoIndex, locked) => () => {
    if (locked) {
      showAccessModal(dispatch);
    } else {
      showGalleryViewer(dispatch, {
        galleries: photos,
        galleryIndex: photoIndex,
      });
    }
  };

  return hasPhotos ? (
    <div className="girl-photos-container">
      <h1 className="section-title mb-5">{title}</h1>
      <br />

      <ThumbnailsCarousel
        key={photos.length}
        className="mb-5"
        itemClassName="px-2"
        small={1}
        medium={3}
        large={6}
      >
        {photos.map((photo, index) => (
          <GalleryThumbnail
            key={photo.id}
            captionClassName="d-none"
            onClick={onThumbnailClick(index, photo.locked)}
            {...photo}
          />
        ))}
      </ThumbnailsCarousel>
    </div>
  ) : null;
};

GirlPhotosCarousel.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

GirlPhotosCarousel.defaultProps = {
  photos: [],
};

export default GirlPhotosCarousel;
