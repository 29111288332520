import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header, Footer } from 'Members/components/layout';
import { AuthModal, Login, Signup } from 'Members/components/Auth';
import {
  VideoThumbnail,
  GirlThumbnail,
  GirlHeaderThumbnail,
  GalleryThumbnail,
  ThumbnailsCarousel,
  GalleriesGrid,
  GalleryViewer,
} from 'Members/components/shared';

const GRAYS = [
  'gray-100',
  'gray-200',
  'gray-300',
  'gray-400',
  'gray-500',
  'gray-600',
  'gray-700',
  'gray-800',
  'gray-900',
  'medium',
  'darker',
];

const COLORS = [
  'blue',
  'indigo',
  'purple',
  'pink',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
];

const THEME_COLORS = [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
  'light',
  'dark',
  'base',
  'section',
];

const VIDEO = {
  id: 354,
  slug: 'food-porn',
  title: 'Food Porn',
  heading: 'Food Porn Video',
  subheading: 'Season 5 • Episode 3',
  category: 'episodes',
  thumbnailUrl: 'https://via.placeholder.com/650x366?text=video',
};

const GIRL = {
  id: 8,
  slug: 'kayla',
  name: 'Kayla ',
  firstName: 'Kayla',
  headerUrl: 'https://via.placeholder.com/1920x1080?text=girl',
  thumbnailUrl: 'https://via.placeholder.com/250x347?text=girl',
};

const GALLERY = {
  id: 367,
  description: 'Hi, honey ❤️',
  thumbnailUrl: 'https://via.placeholder.com/450x450?text=gallery',
  assetUrl: 'https://via.placeholder.com/1280x1920.jpg?text=gallery',
  locked: false,
  girls: [],
};

const VIDEOS = new Array(12).fill(VIDEO);
const GIRLS = new Array(12).fill(GIRL);
const GALLERIES = new Array(12).fill(GALLERY);

const Styleguide = () => {
  const [bg, setBg] = useState('base');
  const altBg = bg === 'base' ? 'section' : 'base';
  const onBackgroundChange = () => setBg(altBg);

  // eslint-disable-next-line
  const ColorBox = ({ color }) => (
    <div className={`bg-${color} text-${color}-contrast col-4 col-md-2 p-5 border border-${altBg}`}>
      {color}
    </div>
  );

  return (
    <div className={`container p-4 bg-${bg} text-${bg}-contrast`}>
      <button
        className={`bg-${altBg} text-${altBg}-contrast`}
        style={{ position: 'fixed', top: '1rem', right: '1rem' }}
        onClick={onBackgroundChange}
      >
        <FontAwesomeIcon icon={['fas', 'broom']} />
      </button>

      <h1 className="row section-title mb-3">Grays</h1>
      <div className="row mb-5">
        {GRAYS.map(color => (
          <ColorBox key={`grays-${color}`} color={color} />
        ))}
      </div>

      <h1 className="row section-title mb-3">Colors</h1>
      <div className="row mb-5">
        {COLORS.map(color => (
          <ColorBox key={`colors-${color}`} color={color} />
        ))}
      </div>

      <h1 className="row section-title mb-3">Theme Colors</h1>
      <div className="row mb-5">
        {THEME_COLORS.map(color => (
          <ColorBox key={`theme-colors-${color}`} color={color} />
        ))}
      </div>

      <h1 className="row section-title mb-3">Navigation</h1>
      <div className="row mb-5">
        <Header dashboardRoutes={[]} photoRoutes={[]} girlsRoutes={[]} settingsRoutes={[]} />
        <Footer />
      </div>

      <h1 className="row section-title mb-3">Buttons</h1>
      <div className="row mb-5">
        <button className="btn btn-cta mr-2 mb-2">.btn-cta</button>
        <button className="btn btn-ghost mr-2 mb-2">.btn-ghost</button>
        <button className="btn btn-accent mr-2 mb-2">.btn-regular</button>
        <button className={`btn btn-regular mr-2 mb-2 text-${bg}-contrast border-${bg}-contrast`}>
          .btn-regular
        </button>
        <button className={`btn btn-nav mr-2 mb-2 text-${bg}-contrast`}>.btn-nav</button>
        <button className={`btn btn-tab mr-2 mb-2 text-${bg}-contrast`}>.btn-tab</button>
        <button className={`btn btn-tag mr-2 mb-2 text-${bg}-contrast`}>.btn-tag</button>
        <button className={`btn btn-upgrade mr-2 mb-2 text-${bg}-contrast border-${bg}-contrast`}>
          .btn-upgrade
        </button>
      </div>

      <h1 className="row section-title">Forms</h1>
      <div className="row mb-5">
        <Login className="auth-form-light" />
        <Signup className="auth-form-light" />
      </div>

      <h1 className="row section-title mb-3">Thumbnails</h1>
      <div className="row mb-5">
        <VideoThumbnail className={`col-12 col-xs-6 col-md-4 text-${bg}-contrast`} {...VIDEO} />
        <GirlThumbnail className={`col-6 col-xs-3 col-md-2 text-${bg}-contrast`} {...GIRL} />
        <GalleryThumbnail className={`col-6 col-md-4 text-${bg}-contrast`} {...GALLERY} />
        <GirlHeaderThumbnail className={`col-12 col-md-6 text-${bg}-contrast`} {...GIRL} />
      </div>

      <h1 className="row section-title mb-3">Carousels</h1>
      <div className="row mb-5">
        <ThumbnailsCarousel
          className={`mb-5 text-${bg}-contrast`}
          itemClassName="px-2"
          small={1}
          medium={2}
          large={4}
        >
          {VIDEOS.map((video, index) => (
            <VideoThumbnail
              key={`video-${video.id}-${index}`}
              className={`text-${bg}-contrast`}
              {...video}
            />
          ))}
        </ThumbnailsCarousel>

        <ThumbnailsCarousel className="my-5" itemClassName="px-2" small={2} medium={4} large={6}>
          {GIRLS.map((girl, index) => (
            <GirlThumbnail
              key={`girl-${girl.id}-${index}`}
              className={`text-${bg}-contrast`}
              {...girl}
            />
          ))}
        </ThumbnailsCarousel>

        <ThumbnailsCarousel className="mb-5" itemClassName="px-2" small={1} medium={3} large={6}>
          {GALLERIES.map((gallery, index) => (
            <GalleryThumbnail
              key={`gallery-${gallery.id}-${index}`}
              captionClassName="d-none"
              {...gallery}
            />
          ))}
        </ThumbnailsCarousel>
      </div>

      <h1 className="row section-title mb-3">Gallery Viewer</h1>
      <div className="mb-5">
        <GalleriesGrid galleries={GALLERIES} thumbnailClassName="col-4" captionClassName="d-none" />
      </div>

      <AuthModal />
      <GalleryViewer />
    </div>
  );
};

export default Styleguide;
