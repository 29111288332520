import { api } from 'Members/utils';
import { SETTINGS_UPDATE } from './types';
import { alertRequestSuccess, alertRequestError } from './common';

export const submitAccountForm = async (dispatch, params) => {
  try {
    const { data } = await api.updateCurrentUser({ customer: params });
    dispatch({ type: SETTINGS_UPDATE, data });
    alertRequestSuccess(dispatch, 'Your account has been successfully updated');
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};
