import React, { useEffect, useState } from 'react';
import { useAppState } from 'Members/hooks';
import { fetchGirls, alertRequestError } from 'Members/actions';
import { GirlHeaderThumbnail } from 'Members/components/shared';
import SiteConfig from 'site_config';

const Girls = () => {
  const [, dispatch] = useAppState();
  const [girls, setGirls] = useState([]);

  const onGirlsFetched = ({ data, error }) => {
    if (error) alertRequestError(dispatch, error);

    setGirls(data);
  };

  useEffect(() => {
    fetchGirls(onGirlsFetched, { by_name: true });
  }, []);

  return (
    <main className="girl-page ma-container py-5 bg-section text-section-contrast">
      <div className="text-center px-3 px-md-5">
        <h1 className="section-title mb-4">{SiteConfig.girls.name}</h1>
        <p className="mx-2 mx-md-5 mb-3">
          {`${SiteConfig.girls.name} is the online destination for the world's sexiest girls! As seen
          in your favorite ${SiteConfig.displayName} episodes, these famous girls are ready to show you
          all of their uncensored private content.`}
        </p>
      </div>
      <div className="row">
        {girls.map(girl => (
          <GirlHeaderThumbnail
            key={girl.id}
            className="col-12 col-md-6 my-3 text-section-contrast"
            {...girl}
          />
        ))}
      </div>
    </main>
  );
};

export default Girls;
