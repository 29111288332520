import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { isGuest, RequireUserType } from 'Members/utils';
import { useAppState } from 'Members/hooks';
import { VitalyPlayer, GirlTagLinks } from 'Members/components/shared';
import Signup from 'Members/components/Auth/Signup';

const VideoBanner = ({ image, url }) => {
  return (
    <div className="video-sponsor text-center p-4 px-2 px-md-5">
      <a href={url} className="d-inline-block" target="_blank" rel="noopener noreferrer">
        <img src={image} className="img-fluid" />
      </a>
    </div>
  );
};

const VideoPlayer = ({
  id,
  heading,
  description,
  subheading,
  category,
  coverUrl,
  iframeUrl,
  videoBanner,
  girls,
}) => {
  const [{ user }] = useAppState();
  const isGuestValue = isGuest(user);
  const [isCollapse, setIsCollapse] = useState(true);

  const type = category === 'episodes' ? subheading : category;
  const hasVideoBanner = !!videoBanner;
  const hasDescription = !!description;

  return (
    <div className="bg-base text-base-contrast">
      <div className="video-player-section">
        <div className="video-player">
          <VitalyPlayer id={id} src={iframeUrl} poster={coverUrl} autoplay />
        </div>
        <RequireUserType guest>
          <div className="video-player-caption">
            <Signup />
          </div>
        </RequireUserType>
      </div>

      {hasVideoBanner && <VideoBanner {...videoBanner} />}

      <div className="video-player-caption py-4">
        <h4 className="video-player-type">{type}</h4>
        <h2 className="video-player-title">{heading}</h2>
        <GirlTagLinks girls={girls} className="mt-3" />
        <div className="video-player-description-container">
          {hasDescription && (
            <Fragment>
              <p
                className={`video-player-description text-muted${
                  !isGuestValue && isCollapse ? ' not-collapse' : ''
                }`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <RequireUserType freemium premium>
                <button
                  className="btn btn-regular btn-sm"
                  onClick={() => setIsCollapse(!isCollapse)}
                  type="button"
                >
                  {isCollapse ? 'READ MORE' : 'READ LESS'}
                </button>
              </RequireUserType>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

VideoBanner.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

VideoPlayer.propTypes = {
  id: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  subheading: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  coverUrl: PropTypes.string.isRequired,
  iframeUrl: PropTypes.string.isRequired,
  videoBanner: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  girls: PropTypes.arrayOf(PropTypes.object),
};

export default VideoPlayer;
