import { REQUEST_SUCCESS, REQUEST_ERROR, HIDE_ALERT, CONFIRM_SHOW, CONFIRM_HIDE } from './types';

export const alertRequestSuccess = (dispatch, message) => {
  dispatch({ type: REQUEST_SUCCESS, data: message });
};

export const alertRequestError = (dispatch, message) => {
  dispatch({ type: REQUEST_ERROR, data: message });
};

export const hideAlert = dispatch => {
  dispatch({ type: HIDE_ALERT });
};

export const showConfirmDialog = (dispatch, { title, text, onSubmit }) => {
  dispatch({ type: CONFIRM_SHOW, data: { title, text, onSubmit } });
};

export const hideConfirmDialog = dispatch => {
  dispatch({ type: CONFIRM_HIDE });
};
