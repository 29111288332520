import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from 'Members/const';
import { tracker, ScrollToTop, Analytics, ProtectedRoute, DefaultHelmet } from 'Members/utils';

import './components/icons';

import Extra from './components/Extra';
import VideoTour from './components/video_tour';
import Alert from './components/Alert';
import { StateProvider } from './state';
import reducer from './reducers';
import ErrorBoundary from './utils/error_boundary';

tracker.initialize();

const AUTH_ROUTES = [
  Routes.login(),
  Routes.forgotPassword(),
  Routes.forgotPasswordWithToken(),
  Routes.tourLogin(),
  Routes.tourForgotPassword(),
  Routes.tourForgotPasswordWithToken(),
];

const EXTRA_ROUTES = [
  Routes.terms(),
  Routes.privacy(),
  Routes.compliance(),
  Routes.refunds(),
  Routes.copyrightAgent(),
];

const RestrictedApp = initialState => {
  return (
    <ErrorBoundary appEnvironment={initialState.appEnvironment}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <Analytics>
          <Alert />
          <ScrollToTop except={AUTH_ROUTES}>
            <DefaultHelmet />
            <Switch>
              <ProtectedRoute path={AUTH_ROUTES} component={VideoTour} exact guest />
              <Route path={EXTRA_ROUTES} component={Extra} exact />
              <Redirect to={Routes.tourLogin()} />
            </Switch>
          </ScrollToTop>
        </Analytics>
      </StateProvider>
    </ErrorBoundary>
  );
};

export default RestrictedApp;
