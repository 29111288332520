/**
 * This utility component allows to check user state before navigation
 * to a route and redirect if necessary.
 *
 * We have three types of users:
 * guests - just visitors who never joined us;
 * freemiums - users who joined us but has no subscription or whose subscription ended;
 * premiums - users with an active subscription.
 * authenticated - users who are logged in.
 *
 * E.g.:
 * <ProtectedRoute path="/only-guests" component={Guest} guest />
 * <ProtectedRoute path="/only-freemiums" component={Freemium} freemium />
 * <ProtectedRoute path="/only-premiums" component={Premium} premium />
 * <ProtectedRoute path="/only-members" component={AuthUserOnly} authenticated />
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAppState } from 'Members/hooks';
import { isPremium, isFreemium, isGuest, isAuthenticated } from 'Members/utils';
import { Routes } from 'Members/const';

const ProtectedRoute = ({
  component: Component,
  authenticated,
  guest,
  freemium,
  premium,
  noscroll,
  redirects,
  ...rest
}) => {
  const [{ user }] = useAppState();

  const isGuestValue = isGuest(user);
  const isLoggedInValue = isAuthenticated(user);
  const isPremiumValue = isPremium(user);
  const isFreemiumValue = isFreemium(user);

  const redirectUrl =
    (isGuestValue && redirects.guest) ||
    (isFreemiumValue && redirects.freemium) ||
    (isPremiumValue && redirects.premium);

  const shouldNotRedirect =
    (guest && isGuestValue) ||
    (freemium && isFreemiumValue) ||
    (premium && isPremiumValue) ||
    (authenticated && isLoggedInValue);

  const renderRoute = props => {
    const redirectRoute = typeof redirectUrl === 'object' ? redirectUrl : { pathname: redirectUrl };
    const redirectTo = { ...redirectRoute, state: { noscroll, from: props.location } };

    return shouldNotRedirect ? <Component {...props} /> : <Redirect to={redirectTo} />;
  };

  return <Route {...rest} render={renderRoute} />;
};

ProtectedRoute.propTypes = {
  guest: PropTypes.bool,
  freemium: PropTypes.bool,
  premium: PropTypes.bool,
  authenticated: PropTypes.bool,
  noscroll: PropTypes.bool,
  redirects: PropTypes.shape({
    guest: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    freemium: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    premium: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

ProtectedRoute.defaultProps = {
  guest: false,
  freemium: false,
  premium: false,
  authenticated: false,
  noscroll: false,
  redirects: {
    guest: Routes.tour(),
    freemium: Routes.tourSignupPayment(),
    premium: Routes.root(),
  },
};

export default ProtectedRoute;
