import { DEVICE_AUTH_STATUS } from 'Members/actions';

export default (device, action) => {
  switch (action.type) {
    case DEVICE_AUTH_STATUS:
      return action.data;
    default:
      return device;
  }
};
