import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PRODUCTION_ENV } from 'Members/const';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    if (this.props.appEnvironment === PRODUCTION_ENV) {
      this.setState({ hasError: true });
    }
  }

  render() {
    /* Throw error when we're in production */
    if (this.state.hasError) {
      // give a little bit of time for sentry to respond, then redirect us to 500.
      setTimeout(() => {
        window.location.href = '/500';
      }, 1200);

      return <div></div>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  appEnvironment: PropTypes.string.isRequired,
};

export default ErrorBoundary;
