import React from 'react';
import { useHistory } from 'react-router';
import { useForm, useAppState } from 'Members/hooks';
import userAvatar from 'images/user.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clickLogoutLink, submitAccountForm } from 'Members/actions';
import { validateAccountForm, validateInstagramForm } from './validations';

const Account = () => {
  const history = useHistory();
  const [{ user }, dispatch] = useAppState();
  const { values, isValid, onChange, onSubmit } = useForm(
    submitAccountForm.bind(this, dispatch),
    validateAccountForm
  );

  const onLogout = async event => {
    event.preventDefault();
    await clickLogoutLink(dispatch);
    history.replace('/login');
  };

  const updateContext = useForm(submitAccountForm.bind(this, dispatch), validateInstagramForm);

  return (
    <div className="account-tab">
      <div className="account-info-container">
        <div className="account-avatar">
          <img className="img-fluid" src={userAvatar} />
        </div>

        <div className="account-info">
          <div className="account-username">
            <FontAwesomeIcon icon={['fal', 'user']} />
            <span>{user.username} - </span>
            <a href="#" onClick={onLogout}>
              Log Out
            </a>
          </div>

          <div className="account-email">
            <FontAwesomeIcon icon={['fal', 'envelope']} />
            <span>{user.email}</span>
          </div>
        </div>
      </div>

      <form className="account-form" onSubmit={updateContext.onSubmit} noValidate>
        <h4 className="account-form-title">Instagram</h4>

        <fieldset>
          <div className="row">
            <input
              name="instagramUsername"
              type="text"
              className="form-control col-7 text-section-contrast"
              placeholder="yourusername"
              value={updateContext.values.instagramUsername || user.instagramUsername || ''}
              onChange={updateContext.onChange}
            />
            <div className="account-form-submit col mt-0">
              <button
                type="submit"
                className="btn btn-regular btn-sm text-section-contrast border-section-contrast"
                disabled={!updateContext.isValid}
              >
                Update Username
              </button>
            </div>
          </div>
        </fieldset>
      </form>

      <br />

      <form className="account-form" onSubmit={onSubmit} noValidate>
        <h4 className="account-form-title">Change Password</h4>

        <fieldset>
          <div className="row">
            <input
              name="currentPassword"
              type="password"
              className="form-control col text-section-contrast"
              placeholder="Current Password"
              autoComplete="current-password"
              value={values.currentPassword || ''}
              onChange={onChange}
            />

            <input
              name="password"
              type="password"
              className="form-control col text-section-contrast"
              placeholder="New Password"
              autoComplete="new-password"
              value={values.password || ''}
              onChange={onChange}
            />

            <input
              name="passwordConfirmation"
              type="password"
              className="form-control col text-section-contrast"
              placeholder="Confirm Password"
              autoComplete="new-password"
              value={values.passwordConfirmation || ''}
              onChange={onChange}
            />
          </div>
        </fieldset>

        <div className="account-form-submit">
          <button
            type="submit"
            className="btn btn-regular btn-sm text-section-contrast border-section-contrast"
            disabled={!isValid}
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default Account;
