import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAppState } from 'Members/hooks';
import { Routes } from 'Members/const';
import Form from './Form';

const DeviceAuth = () => {
  const [{ device }] = useAppState();

  return device ? (
    <Redirect to={Routes.accountSettings()} />
  ) : (
    <main className="device-auth-page ma-container">
      <Form />
    </main>
  );
};

export default DeviceAuth;
