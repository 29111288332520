import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyImage } from 'Members/components/shared';
import { AppLink } from 'Members/utils';
import { Routes } from 'Members/const';

const VideoThumbnail = ({
  id,
  slug,
  title,
  heading,
  description,
  subheading,
  category,
  coverUrl,
  thumbnailUrl,
  iframeUrl,
  publishAt,
  comments,
  girls,
  videoBanner,
  className,
  ...rest
}) => {
  const thumbnailCss = classNames('video-thumb', className);
  const type = subheading || category;

  return (
    <AppLink to={Routes.video({ id, slug })} className={thumbnailCss} {...rest}>
      <div className="video-thumb-image">
        <LazyImage src={thumbnailUrl} observe />
      </div>

      <div className="video-thumb-caption">
        <div className="video-thumb-type">{type}</div>
        <div className="video-thumb-title">{title.toLowerCase()}</div>
      </div>
    </AppLink>
  );
};

VideoThumbnail.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  coverUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  thumbnailUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default VideoThumbnail;
