import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useAppState } from 'Members/hooks';
import { fetchVideo, replyVideoComment } from 'Members/actions';
import { LoadingProgress } from 'Members/components/shared';
import { VideoHelmet } from 'Members/utils';
import VideoPlayer from './VideoPlayer';
import CommentsList from './CommentsList';

const Video = () => {
  const { id } = useParams();
  const videoId = id;
  const [{ video }, dispatch] = useAppState();
  const isLoading = !video || +video.id !== +videoId;
  const onCommentReply = replyId => replyVideoComment(dispatch, replyId);

  useEffect(() => {
    fetchVideo(dispatch, videoId);
  }, []);

  return (
    <main className="video-page ma-container">
      <VideoHelmet {...video} />
      {isLoading && <LoadingProgress />}
      {!isLoading && <VideoPlayer {...video} />}
      {!isLoading && <CommentsList {...video} onCommentReply={onCommentReply} />}
    </main>
  );
};

Video.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Video;
