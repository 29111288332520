import {
  GALLERY_VIEWER_SHOW,
  GALLERY_VIEWER_HIDE,
  GALLERY_VIEWER_NEXT,
  GALLERY_VIEWER_PREV,
} from './types';

export const hideGalleryViewer = dispatch => {
  dispatch({ type: GALLERY_VIEWER_HIDE });
};

export const showGalleryViewer = (dispatch, data) => {
  dispatch({ type: GALLERY_VIEWER_SHOW, data });
};

export const nextGalleryViewer = dispatch => {
  dispatch({ type: GALLERY_VIEWER_NEXT });
};

export const prevGalleryViewer = dispatch => {
  dispatch({ type: GALLERY_VIEWER_PREV });
};
