import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useAppState } from 'Members/hooks';
import { GalleryThumbnail } from 'Members/components/shared';
import { showAccessModal, showGalleryViewer } from 'Members/actions';

const GalleriesGrid = ({ galleries, className, thumbnailClassName, captionClassName }) => {
  const [, dispatch] = useAppState();
  const gridCss = classNames('galleries-grid', className);

  const onThumbnailClick = (galleryIndex, locked) => () => {
    if (locked) {
      showAccessModal(dispatch);
    } else {
      showGalleryViewer(dispatch, { galleries, galleryIndex });
    }
  };

  return (
    <div className={gridCss}>
      <div className="row no-gutters">
        {galleries.map((gallery, index) => (
          <GalleryThumbnail
            key={gallery.id}
            className={thumbnailClassName}
            captionClassName={captionClassName}
            onClick={onThumbnailClick(index, gallery.locked)}
            {...gallery}
          />
        ))}
      </div>
    </div>
  );
};

GalleriesGrid.propTypes = {
  galleries: PropTypes.array.isRequired,
  className: PropTypes.string,
  thumbnailClassName: PropTypes.string,
  captionClassName: PropTypes.string,
};

export default GalleriesGrid;
