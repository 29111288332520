import { api } from 'Members/utils';
import { VIDEOS_FETCHED, VIDEO_FETCHED } from './types';
import { alertRequestError } from './common';

export const fetchVideos = async dispatch => {
  try {
    const { data } = await api.getVideos();
    dispatch({ type: VIDEOS_FETCHED, data });
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};

export const fetchVideo = async (dispatch, videoId) => {
  try {
    const { data } = await api.getVideo(videoId);
    dispatch({ type: VIDEO_FETCHED, data });
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};
