import React, { Fragment } from 'react';
import SiteConfig from 'site_config';

const Terms = () => {
  return (
    <div className="extra-terms container mb-5 mt-4">
      <h2>
        <b>Terms of Service</b>
      </h2>
      <p>
        {`Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before
        using the ${SiteConfig.displayName} website (the “Service”) owned by ${SiteConfig.company.name}
        (“us”, “we”, or “our”).`}
      </p>
      <p>
        Your access to and use of the Service is conditioned upon your acceptance of and compliance
        with these Terms. These Terms apply to all visitors, users and others who wish to access or
        use the Service.
      </p>
      <p>
        By accessing or using the Service you agree to be bound by these Terms. If you disagree with
        any part of the terms then you do not have permission to access the Service.
      </p>
      <p>
        Section 230(d) Notice:&nbsp;In accordance with&nbsp;
        <a
          href="https://www.law.cornell.edu/uscode/text/47/230"
          target="_blank"
          rel="noopener noreferrer"
        >
          47 U.S.C.&nbsp; §&nbsp;230(d)
        </a>
        , you are notified that parental control protections (including computer hardware, software,
        or filtering services) are commercially available that may help in limiting access to
        material that is harmful to minors. You may find information about providers of these
        protections on the Internet by searching “parental control protection” or similar terms. If
        minors have access to your computer, please restrain their access to sexually explicit
        material by using any of the following products, which the Company provides for
        informational purposes only and does not endorse:&nbsp;
        <a href="http://www.cybersitter.com/" target="_blank" rel="noopener noreferrer">
          CYBERsitter™
        </a>
        &nbsp;|&nbsp;
        <a href="http://www.netnanny.com/" target="_blank" rel="noopener noreferrer">
          Net Nanny®
        </a>
        &nbsp;|&nbsp;
        <a href="http://www.cyberpatrol.com/" target="_blank" rel="noopener noreferrer">
          CyberPatrol
        </a>
        &nbsp;|&nbsp;
        <a href="http://www.asacp.org/" target="_blank" rel="noopener noreferrer">
          ASACP
        </a>
        .
      </p>
      <p>
        No Minors.&nbsp;The Service contains adult-oriented content and is not intended for minors.
        Only adults (1) who are at least 18-years old and (2) who have reached the age of majority
        where they live may access the Service. The Company forbids all persons who do not meet
        these age requirements from accessing the Service.
      </p>
      <p>
        <b>Subscriptions</b>
      </p>
      <p>
        Some parts of the Service are billed on a subscription basis (“Subscription(s)”). You will
        be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are
        set either on a monthly or annual basis, depending on the type of subscription plan you
        select when purchasing a Subscription.
      </p>
      <p>
        {`At the end of each Billing Cycle, your Subscription will automatically renew under the exact
        same conditions unless you cancel it or ${SiteConfig.company.name} cancels it. You may cancel your
        Subscription renewal either through your online account management page or by contacting
        ${SiteConfig.company.name} customer support team.`}
      </p>
      <p>
        {`Should automatic billing fail to occur for any reason, ${SiteConfig.company.name} will issue an
        electronic invoice indicating that you must proceed manually, within a certain deadline
        date, with the full payment corresponding to the billing period as indicated on the invoice.`}
      </p>
      <p>
        <b>Free Trial</b>
      </p>
      <p>
        {`${SiteConfig.displayName} team may, at its sole discretion, offer a Subscription with a free trial
        for a limited period of time (“Free Trial”).`}
      </p>
      <p>
        You may be required to enter your billing information in order to sign up for the Free
        Trial.
      </p>
      <p>
        {`If you do enter your billing information when signing up for the Free Trial, you will not be
        charged by ${SiteConfig.displayName} (owned by ${SiteConfig.company.name}) until the Free Trial has expired.
        On the last day of the Free Trial period, unless you cancelled your Subscription, you will
        be automatically charged the applicable Subscription fees for the type of Subscription you
        have selected.`}
      </p>
      <p>
        {`At any time and without notice, ${SiteConfig.company.name} reserves the right to (i) modify the terms
        and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.`}
      </p>
      <p>
        <b>Refunds</b>
      </p>
      <p>
        Please refer to our&nbsp;
        <a href="/refunds" target="_blank" rel="noopener">
          Refund Policy.
        </a>
      </p>
      <p>
        <b>Fee Changes</b>
      </p>
      <p>
        {`${SiteConfig.company.name}, in its sole discretion and at any time, may modify the Subscription fees
        for the Subscriptions. Any Subscription fee change will become effective at the end of the
        then-current Billing Cycle.`}
      </p>
      <p>
        {`${SiteConfig.company.name} will provide you with a reasonable prior notice of any change in
        Subscription fees to give you an opportunity to terminate your Subscription before such
        change becomes effective.`}
      </p>
      <p>
        Your continued use of the Service after the Subscription fee change comes into effect
        constitutes your agreement to pay the modified Subscription fee amount.
      </p>
      <p>
        <b>Accounts</b>
      </p>
      <p>
        When you create an account with us, you guarantee that you are above the age of 18, and that
        the information you provide us is accurate, complete, and current at all times. Inaccurate,
        incomplete, or obsolete information may result in the immediate termination of your account
        on the Service.
      </p>
      <p>
        You are responsible for maintaining the confidentiality of your account and password,
        including but not limited to the restriction of access to your computer and/or account. You
        agree to accept responsibility for any and all activities or actions that occur under your
        account and/or password, whether your password is with our Service or a third-party service.
        You must notify us immediately upon becoming aware of any breach of security or unauthorized
        use of your account.
      </p>
      <p>
        You may not use as a username the name of another person or entity or that is not lawfully
        available for use, a name or trademark that is subject to any rights of another person or
        entity other than you, without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
      </p>
      <p>
        <b>Intellectual Property</b>
      </p>
      <p>
        {`The Service and its original content, features and functionality are and will remain the
        exclusive property of ${SiteConfig.company.name} and its licensors. The Service is protected by
        copyright, trademark, and other laws of both the United States and foreign countries. Our
        trademarks and trade dress may not be used in connection with any product or service without
        the prior written consent of ${SiteConfig.company.name}.`}
      </p>
      <p>
        <b>Links To Other Web Sites</b>
      </p>
      <p>
        {`Our Service may contain links to third party web sites or services that are not owned or
        controlled by ${SiteConfig.company.name}`}
      </p>
      <p>
        {`${SiteConfig.company.name} has no control over, and assumes no responsibility for the content, privacy
        policies, or practices of any third party web sites or services. We do not warrant the
        offerings of any of these entities/individuals or their websites.`}
      </p>
      <p>
        {`You acknowledge and agree that ${SiteConfig.company.name} shall not be responsible or liable, directly
        or indirectly, for any damage or loss caused or alleged to be caused by or in connection
        with use of or reliance on any such content, goods or services available on or through any
        such third party web sites or services.`}
      </p>
      <p>
        We strongly advise you to read the terms and conditions and privacy policies of any third
        party web sites or services that you visit.
      </p>
      <p>
        <b>Termination</b>
      </p>
      <p>
        We may terminate or suspend your account and bar access to the Service immediately, without
        prior notice or liability, under our sole discretion, for any reason whatsoever and without
        limitation, including but not limited to a breach of the Terms.
      </p>
      <p>If you wish to terminate your account, you may simply discontinue using the Service.</p>
      <p>
        All provisions of the Terms which by their nature should survive termination shall survive
        termination, including, without limitation, ownership provisions, warranty disclaimers,
        indemnity and limitations of liability.
      </p>
      <p>
        <b>Indemnification</b>
      </p>
      <p>
        {`You agree to defend, indemnify and hold harmless ${SiteConfig.company.name} and its licensee and
        licensors, and their employees, contractors, agents, officers and directors, from and
        against any and all claims, damages, obligations, losses, liabilities, costs or debt, and
        expenses (including but not limited to attorney’s fees), resulting from or arising out of a)
        your use and access of the Service, by you or any person using your account and password, or
        b) a breach of these Terms.`}
      </p>
      <p>
        <b>Limitation Of Liability</b>
      </p>
      <p>
        {`In no event shall ${SiteConfig.displayName} team, ${SiteConfig.company.name}, nor its directors, employees,
        partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special,
        consequential or punitive damages, including without limitation, loss of profits, data, use,
        goodwill, or other intangible losses, resulting from (i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of any third party on
        the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use
        or alteration of your transmissions or content, whether based on warranty, contract, tort
        (including negligence) or any other legal theory, whether or not we have been informed of
        the possibility of such damage, and even if a remedy set forth herein is found to have
        failed of its essential purpose.`}
      </p>
      <p>
        <b>Disclaimer</b>
      </p>
      <p>
        Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS
        AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or
        implied, including, but not limited to, implied warranties of merchantability, fitness for a
        particular purpose, non-infringement or course of performance.
      </p>
      <p>
        {`${SiteConfig.company.name} its subsidiaries, affiliates, and its licensors do not warrant that a) the
        Service will function uninterrupted, secure or available at any particular time or location;
        b) any errors or defects will be corrected; c) the Service is free of viruses or other
        harmful components; or d) the results of using the Service will meet your requirements.`}
      </p>
      <p>
        <b>Exclusions</b>
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or
        limitation of liability for consequential or incidental damages, so the limitations above
        may not apply to you.
      </p>
      <p>
        <b>Governing Law</b>
      </p>
      <p>
        {`These Terms shall be governed and construed in accordance with the laws of
        ${SiteConfig.company.governingLaw}, without regard to its conflict of law provisions.`}
      </p>
      <p>
        Our failure to enforce any right or provision of these Terms will not be considered a waiver
        of those rights. If any provision of these Terms is held to be invalid or unenforceable by a
        court, the remaining provisions of these Terms will remain in effect. These Terms constitute
        the entire agreement between us regarding our Service, and supersede and replace any prior
        agreements we might have had between us regarding the Service.
      </p>
      <p>
        <b>Changes</b>
      </p>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
        If a revision is material we will provide at least 30 days notice prior to any new terms
        taking effect. What constitutes a material change will be determined at our sole discretion.
      </p>
      <p>
        By continuing to access or use our Service after any revisions become effective, you agree
        to be bound by the revised terms. If you do not agree to the new terms, you are no longer
        authorized to use the Service.
      </p>
      <p>
        <b>No Third-Party Beneficiaires</b>
      </p>
      <p>
        This agreement does not, and the parties do not intend it to, confer any rights or remedies
        on any person other than the parties to this agreement.
      </p>
      <p>
        <b>Permission to Email You</b>
      </p>
      <p>
        You grant the Company permission to email you notices, advertisements, and other
        communications to you, including emails, advertisements, notices, and other communications
        containing adult oriented material, sexual content and language, and images of nudity
        unsuitable for minors. Your permission will continue until you ask the Company to remove you
        from its email list. For more information, please see the&nbsp;
        <a href="/privacy" target="_blank" rel="noopener">
          Privacy Policy
        </a>
        .
      </p>
      <p>
        <b>Electronic Signatures</b>
      </p>
      <p>
        Any affirmation, assent, or agreement you send through the Service will bind you. You
        acknowledge that when you click on an “I agree,” “I consent,” or other similarly worded
        “button” or entry field with your finger, mouse, keystroke, or other device, your agreement
        or consent will be legally binding and enforceable and the legal equivalent of your
        handwritten signature.
      </p>
      <p>
        <b>Consumer Rights Information – California Residents Only</b>
      </p>
      <p>
        This section applies only to California residents. In compliance with Section 1789 of the
        California Civil Code, please note the following:
      </p>
      <p>
        {SiteConfig.company.name}
        <br />
        {SiteConfig.company.address.map((address, index) => (
          <Fragment key={index}>
            {address}
            <br />
          </Fragment>
        ))}
      </p>
      <p>
        <b>Complains – California Residents</b>
      </p>
      <p>
        You may contact in writing the Complaint Assistance Unit of the Division of Consumer
        Services of the Department of Consumer Affairs at 1020 North Street, #501, Sacramento,
        California 95814, or by telephone at +1 (916) 445-1254.
      </p>
      <p>
        <b>Feedback</b>
      </p>
      <p>
        The Company encourage you to provide feedback about the Service. But the Company will not
        treat as confidential any suggestion or idea provided by you, and nothing in this agreement
        will restrict its right to use, profit from, disclose, publish, or otherwise exploit any
        feedback, without payment to you.
      </p>
      <p>
        <b>English Language</b>
      </p>
      <p>
        The Company drafted this agreement in the English language. No translation into any other
        language will be used to interpret or construe this agreement. The Company will provide all
        services, support, notices, designations, specifications, and communications in English.
      </p>
      <p>
        <b>Contact Us</b>
      </p>
      <p>
        You should direct all feedback, comments, requests for technical support, and other
        communications relating to the Website to&nbsp;
        <a href={`mailto:${SiteConfig.company.supportEmail}`}>{SiteConfig.company.supportEmail}</a>.
      </p>
    </div>
  );
};

export default Terms;
