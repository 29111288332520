import { api } from 'Members/utils';
import { GIRL_FETCHED } from './types';
import { alertRequestError } from './common';

export const fetchGirls = async (dispatch, params = { random: false }) => {
  try {
    const { data } = await api.getGirls(params);
    dispatch({ data });
  } catch ({ message }) {
    dispatch({ error: message });
  }
};

export const fetchGirl = async (dispatch, girlId) => {
  try {
    const { data } = await api.getGirl(girlId);
    dispatch({ type: GIRL_FETCHED, data });
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};

export const fetchGirlGalleries = async (dispatch, girlId, page = 0) => {
  try {
    const { data, meta } = await api.getGirlGalleries(girlId, { page });
    dispatch({ data, meta });
  } catch ({ message }) {
    dispatch({ error: message });
  }
};
