import {
  AUTH_LOGIN,
  AUTH_SIGNUP,
  AUTH_LOGOUT,
  AUTH_PURCHASE,
  SETTINGS_UPDATE,
} from 'Members/actions';

export default (user, action) => {
  switch (action.type) {
    case AUTH_SIGNUP:
    case AUTH_LOGIN:
    case AUTH_PURCHASE:
    case SETTINGS_UPDATE:
      return action.data;
    case AUTH_LOGOUT:
      return null;
    default:
      return user;
  }
};
