/* Store split test information */
export const SPLIT_TESTS = {
  /*
    March 3rd, 2020
    Split test for cross-sale functionality.
    0. Original is using a checkbox
    1. Dropdown select box for xsale
    xsale_join_page: {
      name: '20200303_jpxs',
      experimentId: 'WF5EPueATt68OV0kiKAs7A',
      variations: 2,
      winner: null
    },
    Turned off June 09, 2020 -- restarted test based on new site release
    xsale_join_page_with_new_site: {
      name: '20200422_jpxs_withnewsite',
      experimentId: 'WF5EPueATt68OV0kiKAs7A',
      variations: 2,
      winner: 1,
    },
  */
};
