import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Routes,
  AUTH_ROUTES,
  MAIN_ROUTES,
  EXTRA_ROUTES,
  GIRL_TOUR_ROUTES,
  VIDEO_TOUR_ROUTES,
} from 'Members/const';
import { tracker, ScrollToTop, Analytics, ProtectedRoute, DefaultHelmet } from 'Members/utils';

import './components/icons';

import Main from './components/Main';
import Extra from './components/Extra';
import VideoTour from './components/video_tour';
import GirlTour from './components/girl_tour';
import Styleguide from './components/styleguide';
import { Footer } from './components/layout';
import Alert from './components/Alert';
import ConfirmDialog from './components/confirm_dialog';
import { StateProvider } from './state';
import reducer from './reducers';
import ErrorBoundary from './utils/error_boundary';
import RestrictedApp from './restricted_app';
import FreshChatWidget from './components/fresh_chat_widget';

tracker.initialize();

const MembersApp = initialState => {
  if (initialState.restrictedApp) return <RestrictedApp {...initialState} />;

  return (
    <ErrorBoundary appEnvironment={initialState.appEnvironment}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <Analytics>
          <Alert />
          <ConfirmDialog />
          <ScrollToTop except={AUTH_ROUTES}>
            <DefaultHelmet />
            <Switch>
              <ProtectedRoute
                path={[...AUTH_ROUTES, ...VIDEO_TOUR_ROUTES]}
                component={VideoTour}
                exact
                guest
                freemium
              />
              <ProtectedRoute path={MAIN_ROUTES} component={Main} exact premium />
              <Route path={GIRL_TOUR_ROUTES} component={GirlTour} exact />
              <Route path={EXTRA_ROUTES} component={Extra} exact />
              <Route path={Routes.styleguide()} component={Styleguide} exact />
              <Redirect to={Routes.root()} />
            </Switch>
          </ScrollToTop>

          <Footer />
          <FreshChatWidget />
        </Analytics>
      </StateProvider>
    </ErrorBoundary>
  );
};

MembersApp.propTypes = {
  user: PropTypes.object,
  featuredCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    })
  ),
};

export default MembersApp;
