import { api } from 'Members/utils';

import { DEVICE_AUTH_STATUS } from './types';
import { alertRequestError } from './common';

export const getDeviceAuthStatus = async dispatch => {
  try {
    const { data } = await api.getDeviceAuthStatus();
    dispatch({ type: DEVICE_AUTH_STATUS, data: data.status });
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};

export const submitDeviceAuthForm = async (dispatch, params) => {
  try {
    const { data } = await api.authorizeDevice({ customer: params });
    dispatch({ type: DEVICE_AUTH_STATUS, data: data.status });
  } catch ({ message }) {
    alertRequestError(dispatch, message);
  }
};

export const resetDeviceAuthStatus = async dispatch => {
  dispatch({ type: DEVICE_AUTH_STATUS, data: undefined });
};
