import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAppState, useForm, useDeviceAuth } from 'Members/hooks';
import { fetchProductBillingGroup, hideModal } from 'Members/actions';
import { hasAccessToProduct, tracker } from 'Members/utils';
import { LoadingProgress } from 'Members/components/shared';
import InlineAlert from 'Members/components/InlineAlert';
import { monthlyPackage, oneClickPurchaseText } from 'Members/utils/purchase_helper';
import { SubmitButton } from './Form';

const OneClick = ({ productId, productName, submitAction }) => {
  const [{ user, billingDescriptor }, dispatch] = useAppState();
  const [displayPackage, setDisplayPackage] = useState(null);
  const { device, isGettingStatus } = useDeviceAuth();
  const purchaseText = displayPackage ? oneClickPurchaseText(productName, displayPackage) : '';
  const isLoading = !displayPackage;

  const { formAlert, isSubmitting, isValid, onSubmit, setValues, setFormAlert } = useForm(
    submitAction.bind(this, dispatch),
    () => ({})
  );

  const onProductBillingGroupFetched = ({ data, error }) => {
    if (error) {
      setFormAlert({ type: 'danger', message: error });
      return;
    }

    const billingGroup = data;
    const oneClickPackage = monthlyPackage(billingGroup);

    setDisplayPackage(oneClickPackage);
    setValues({ productId, packageId: oneClickPackage.id });
  };

  // Request billing group data for the product.
  useEffect(() => {
    fetchProductBillingGroup(onProductBillingGroupFetched, { productId });
  }, [productId]);

  // Track device authorization status, but do not ask a user
  // to authorize his device just for now.
  useEffect(() => {
    if (!isGettingStatus) tracker.deviceAuthorized(user.id, device);
  }, [isGettingStatus]);

  // Do not render the form / hide modal if a user has access to the product.
  if (hasAccessToProduct(user, productId)) {
    hideModal(dispatch);
    return null;
  }

  return (
    <form className="auth-one-click-form auth-form" onSubmit={onSubmit} noValidate>
      <h1>
        Confirm
        <br />
        Your Purchase
      </h1>

      {formAlert && <InlineAlert icon={{ icon: ['fal', 'exclamation-triangle'] }} {...formAlert} />}
      {isLoading && <LoadingProgress />}

      {!isLoading && (
        <Fragment>
          <p>{purchaseText}</p>
          <p>
            Your card statement will read - <i>{billingDescriptor}</i>.
          </p>

          <SubmitButton
            text="Purchase Now"
            disabled={() => isSubmitting || !isValid}
            isSubmitting={isSubmitting}
            loadingInstruction="Do Not Refresh Browser"
          />
        </Fragment>
      )}
    </form>
  );
};

OneClick.propTypes = {
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  submitAction: PropTypes.func,
};

export default OneClick;
