import React from 'react';
import { useAppState } from 'Members/hooks';
import { submitProductOneClickForm } from 'Members/actions';
import OneClick from './one_click';

const GirlOneClick = props => {
  const [{ girl }] = useAppState();

  return (
    <OneClick
      productId={girl.productId}
      productName={`${girl.name}'s private updates`}
      submitAction={submitProductOneClickForm}
      {...props}
    />
  );
};

export default GirlOneClick;
