import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppLink } from 'Members/utils';
import { Routes } from 'Members/const';
import SiteConfig from 'site_config';

const youtubeVideoSrc = id =>
  `//www.youtube.com/embed/${id}?autoplay=1&amp;playsinline=1&amp;modestbranding=1&amp;controls=0`;

const VideoModal = ({ id, onClose }) => {
  return ReactDOM.createPortal(
    <div className="video-modal-container" onClick={onClose}>
      <div className="video-modal">
        <div className="video-modal-close" onClick={onClose}>
          <FontAwesomeIcon icon={['fal', 'times']} size="3x" />
        </div>

        <div className="video-modal-body">
          <iframe width="100%" height="100%" src={youtubeVideoSrc(id)} frameBorder="0" />
        </div>
      </div>
    </div>,
    document.body
  );
};

VideoModal.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const WelcomeForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // The video isn't available anymore, so disabling the play button for now.
  // See: https://skintertainment.atlassian.net/browse/VU-244.
  //
  // <img className="welcome-form-play-button" src={playIcon} onClick={onPlayClick} />
  // const onPlayClick = () => setIsModalVisible(true);
  const onVideoModalClose = () => setIsModalVisible(false);

  return (
    <div className="welcome-form-container">
      {isModalVisible && <VideoModal id="lNSHGMFlKqE" onClose={onVideoModalClose} />}

      <div className="welcome-form-title">
        {SiteConfig.videoTour.slogan.map(line => (
          <h1 key={line}>{line}</h1>
        ))}
      </div>

      <div className="welcome-form">
        <AppLink to={Routes.tourLogin()} className="btn btn-ghost" noscroll>
          Login
        </AppLink>
      </div>
    </div>
  );
};

export default WelcomeForm;
