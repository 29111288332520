const PENDING_CANCEL_MSG =
  'Your cancellation request is being processed. Confirmation will be sent to your e-mail address when completed.';

export function subscriptionStatusDescription({ activeSince, expireAt, status }) {
  switch (status) {
    case 'pending cancellation':
      return PENDING_CANCEL_MSG;
    case 'expired':
      return `Your subscription has been expired since ${expireAt}`;
    case 'cancelled':
      return `Your access will expire on ${expireAt}`;
    case 'active':
      return `Your subscription has been active since ${activeSince}`;
    default:
      return '';
  }
}

export function isPremium(user) {
  return !!(user && user.subscriptionStatus === 'active');
}

export function isGuest(user) {
  return !user;
}

export function isFreemium(user) {
  return !isGuest(user) && !isPremium(user);
}

export function isAuthenticated(user) {
  return !isGuest(user);
}

// We require them to be premiums for now, but potentially it's also freemiums.
export function hasCreditCard(user) {
  return isPremium(user);
}

export function isSubscriptionToProduct(subscription = {}, productId) {
  const status = (subscription.status || '').toLowerCase();

  return (
    subscription.productId === productId &&
    (subscription.free || status === 'active' || status === 'cancelled')
  );
}

// If there is no product ID, the content is free.
export function hasAccessToProduct(user, productId) {
  return (
    !productId ||
    (user &&
      user.subscriptions &&
      user.subscriptions.some(subscription => isSubscriptionToProduct(subscription, productId)))
  );
}
