import React from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'Members/hooks';
import { showAccessModal } from 'Members/actions';
import { isPremium } from 'Members/utils';
import { VideoThumbnail, ThumbnailsCarousel } from 'Members/components/shared';

const GirlVideosCarousel = ({ videos, title }) => {
  const [{ user }, dispatch] = useAppState();
  const hasVideos = videos && videos.length > 0;

  // If they don't have VU subscription, do not let them view the episodes
  // and show the access modal instead.
  const onThumbnailClick = event => {
    if (isPremium(user)) return;

    event.preventDefault();
    showAccessModal(dispatch);
  };

  return hasVideos ? (
    <div className="girl-videos-container">
      <h1 className="section-title mb-5">{title}</h1>
      <br />

      <ThumbnailsCarousel
        key={videos.length}
        className="mb-5 text-section-contrast"
        itemClassName="px-2"
        small={1}
        medium={2}
        large={4}
      >
        {videos.map(video => (
          <VideoThumbnail
            key={video.id}
            className="text-section-contrast"
            onClick={onThumbnailClick}
            {...video}
          />
        ))}
      </ThumbnailsCarousel>
    </div>
  ) : null;
};

GirlVideosCarousel.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

GirlVideosCarousel.defaultProps = {
  videos: [],
};

export default GirlVideosCarousel;
